import { httpClient } from 'src/api/axios/api'
import {
  BusinessGetUsersByUserBusiness,
  GetBusinesses,
  GetBusinessesRelated,
  GetInternalUsers,
} from './urls'

export const getCountry = () => {
  return `query {
    countryDataResponse: getCountry {
      countryDescription
      countryCode
      stateInfo {
        code
        description
      }
    }
  }`
}

export const getBusinessesQuery = (clientId: string | null) => {
  return `query{
    getBusinesses(businessListRequest: { clientIdFilter: ${
      clientId || null
    } }) {
      businesses {
        id
        name
        status      
        type: businessType
        buyerType      
        emailAddress  
        assetTypes
        servicerType
      }   
    }
  }`
}

export const getBuyerQuery = (buyerId: string) => {
  return `query {
    getBuyer(buyerId: "${buyerId}") {
      id
      businessType
      status
      name
      buyerType: bCOBuyerType
    }
  }`
}

export const getUserBusiness = async (
  userId: string | undefined
): Promise<any> => {
  const response = await httpClient.get(BusinessGetUsersByUserBusiness, {
    params: {
      userId,
    },
  })
  return response?.data
}

export const getBusinesses = async (
  clientId: number | undefined
): Promise<any> => {
  const response = await httpClient.get(GetBusinesses, {
    params: {
      request: {
        clientIdFilter: clientId,
      },
    },
  })
  return response?.data
}

export const getBusinessesRelated = async (
  ids: string,
  businessType: string
): Promise<any> => {
  const response = await httpClient.get(GetBusinessesRelated, {
    params: {
      businesses: ids,
      businessType,
    },
  })
  return response?.data
}

export const getInternalUsers = async (): Promise<any> => {
  const response = await httpClient.get(GetInternalUsers)
  return response?.data
}
