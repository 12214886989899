import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  List,
  ListItem,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'

import React, { useEffect, useState } from 'react'
import { Close } from '@material-ui/icons'

import axios from 'axios'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import { useQuery } from 'react-query'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { DialogTitleStyled } from '../Dialogs/style'
import Loader from '../Loader'

interface IReviewQuestion {
  open: boolean
  close: () => void
  businessUploadId: number
}

const ReviewQuestion: React.FC<IReviewQuestion> = ({
  open,
  close,
  businessUploadId,
}: IReviewQuestion) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const userToken = GetAccessToken()
  const [review, setReview] = useState<any[]>([])
  const { enqueueSnackbar } = useSnackbar()

  const notistackSucces = notistackOptions('success')
  const notistackError = notistackOptions('error')

  const onCloseDialog = () => close()

  const { isLoading: loadingReview } = useQuery({
    queryKey: ['getReviewQuestionnaire'],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/businessUploadRequest/getReviewQuestionnaire`,
        params: { businessUploadId },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setReview(result.data)
      })
    },
    cacheTime: 0,
  })

  useEffect(() => {
    setOpenDialog(open)
  }, [open])

  const handleChange = (index: number, field: string, value: any) => {
    const updatedFormData = [...review]
    updatedFormData[index] = { ...updatedFormData[index], [field]: value }
    setReview(updatedFormData)
  }

  const handleSubmit = (e: any) => {
    setLoading(true)
    axios({
      method: 'post',
      url: `${BACKEND_URL}/BusinessUploadRequest/SaveReviewQuestionnaire`,
      params: { request: JSON.stringify(review), businessUploadId },
      headers: {
        Authorization: userToken,
      },
    })
      .then(() => {
        enqueueSnackbar('New data added', notistackSucces)
        setLoading(false)
        setOpenDialog(false)
        close()
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data, notistackError)
        setLoading(false)
      })
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={onCloseDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        maxWidth="lg"
      >
        <DialogTitleStyled id="confirm-dialog-title">
          <Typography
            variant="body1"
            style={{
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 400,
              lineHeight: 1.2,
              textAlign: 'center',
            }}
          >
            Review Questionnaire
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseDialog}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent
          style={{
            width: '700px',
            height: '500px',
          }}
        >
          <form onSubmit={handleSubmit}>
            {(loadingReview || loading) && <Loader />}
            <List>
              {review?.map((m: any, index: number) => (
                <ListItem key={`item${index}`}>
                  <Box style={{ width: '100%' }}>
                    <Box
                      style={{ width: '100%' }}
                      display="flex"
                      justifyContent="center"
                    >
                      <Box
                        style={{ width: '100%' }}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                      >
                        <Box
                          display="flex"
                          justifyContent="center"
                          flexDirection="column"
                        >
                          <Typography id={`type${index}`} key={`type${index}`}>
                            {m.question}
                          </Typography>
                        </Box>
                        <Box
                          paddingLeft={5}
                          display="flex"
                          justifyContent="center"
                          flexDirection="column"
                        >
                          <TextField
                            data-cy="select-option"
                            id="select-option"
                            fullWidth
                            select
                            style={{ width: '120px' }}
                            label="Answer"
                            name="answer"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e) =>
                              // eslint-disable-next-line prettier/prettier
                          handleChange(index, 'answer', e.target.value)}
                            value={m.answer || null}
                          >
                            <MenuItem value="Yes" key="Yes">
                              Yes
                            </MenuItem>
                            <MenuItem value="No" key="No">
                              No
                            </MenuItem>
                            <MenuItem value="Other" key="Other">
                              Other
                            </MenuItem>
                          </TextField>
                        </Box>
                      </Box>
                    </Box>
                    {m.answer === 'Other' && (
                      <Box
                        paddingTop={1}
                        style={{ width: '100%' }}
                        display="flex"
                        justifyContent="center"
                      >
                        <TextField
                          name="otherAnswer"
                          variant="outlined"
                          fullWidth
                          multiline={true}
                          minRows={3}
                          label="Answer"
                          style={{ width: '100%' }}
                          inputProps={{ maxLength: 200 }}
                          value={m.otherAnswer}
                          onChange={
                            (e) =>
                              handleChange(index, 'otherAnswer', e.target.value)
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                        />
                      </Box>
                    )}
                  </Box>
                </ListItem>
              ))}
            </List>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            type="button"
            autoFocus
            disabled={loadingReview || loading}
            onClick={handleSubmit}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ReviewQuestion
