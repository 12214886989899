import React from 'react'
import ReactDOM from 'react-dom'
import '@progress/kendo-theme-material/dist/all.css'

import * as serviceWorker from './serviceWorker'

import Root from './root'
import ErrorBoundary from './Error'

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Root />
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.unregister()
