import styled, { keyframes } from 'styled-components'

interface EllipseProps {
  top?: number
  left?: number
  right?: number
  bottom?: number
  position?: 'relative' | 'absolute' | 'fixed' | string
}

export const Ellipse = styled.div<EllipseProps>`
  position: ${({ position }): string => position || 'relative'};
  top: ${({ top }) => `${top}px` || 0};
  left: ${({ left }) => `${left}px` || 0};
  right: ${({ right }) => `${right}px` || 0};
  bottom: ${({ bottom }) => `${bottom}px` || 0};
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 100px;
  width: 60vw;
  height: 40vw;
`

const fadeAnimation = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.6);
  }
  50% {
    opacity: 0.6;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

export const AcessDiniedContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
  color: #002e3a;
  .icon-fade {
    animation: ${fadeAnimation} 1s linear alternate;
  }
  .denied-img {
    display: block;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
  .subtitle {
    font-size: 40px;
    font-weight: 400;
  }
`
