import React from 'react'

interface CategoryOrderBoxProps {
  boxId: string
  boxName: string
  handleDrag: (ev: any) => void
  handleDrop: (ev: any) => void
  cursor: string
}

const CategoryOrderBox: React.FC<CategoryOrderBoxProps> = ({
  boxId,
  boxName,
  handleDrag,
  handleDrop,
  cursor,
}: CategoryOrderBoxProps) => {
  return (
    <div
      id={boxId}
      draggable
      onDragOver={(ev) => ev.preventDefault()}
      onDragStart={handleDrag}
      onDrop={handleDrop}
      style={{
        margin: '12px',
        paddingLeft: '10px',
        paddingRight: '10px',
        border: '1px solid',
        borderColor: 'grey',
        borderRadius: '5px',
        height: '50px',
        textAlign: 'center',
        alignItems: 'center',
        cursor,
      }}
    >
      <h3>{boxName}</h3>
    </div>
  )
}

export default CategoryOrderBox
