import React, { useEffect } from 'react'
import AuthService from 'src/configs/AuthService'

export const SignOutCallback: React.FC = () => {
  useEffect(() => {
    const authService = new AuthService()
    authService.logoutCallback()
  }, [])

  return <div />
}
