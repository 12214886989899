import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  ListItemIcon,
  Box,
} from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { IJurisdiction } from 'src/api/models'
import { BACKEND_URL } from 'src/api/axios/api'
import Close from '@material-ui/icons/Close'
import Delete from '@material-ui/icons/Delete'
import { BusinessContext } from 'src/context/BusinessContext'
import { GetAccessToken } from 'src/api/axios/helper'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import Skeleton from '@material-ui/lab/Skeleton'
import { AuthContext } from 'src/context/AuthenticationContext'
import { isUkCountry } from 'src/utils/common'
import MultipleSelectionDropDown from 'src/components/MultipleSelectionDropDown'
import { DialogTitleStyled } from '../Dialogs/style'

interface LicenseAttestationFormProps {
  open: boolean
  close: () => void
}

interface ILicenseAttestation {
  jurisdiction: number[]
}

const LicenseAttestationForm: React.FC<LicenseAttestationFormProps> = ({
  open,
  close,
}: LicenseAttestationFormProps) => {
  const { enqueueSnackbar } = useSnackbar()

  const notistackSucces = notistackOptions('success')
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [jurisdictionSelected, setJurisdictionSelected] = useState<any[]>([])
  const [initialValues, setInitialValues] = useState<ILicenseAttestation>({
    jurisdiction: [],
  })
  const userToken = GetAccessToken()
  const queryClient = useQueryClient()
  const { profileClient } = useContext(AuthContext)
  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )

  const onCloseDialog = () => close()
  const businessSelected = useContext<any>(BusinessContext)
  useEffect(() => {
    setOpenDialog(open)
  }, [open])

  const jurisdictionDataQuery = useQuery({
    queryKey: ['getAllJurisdiction', businessSelected.id],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/jurisdiction`,
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data.jurisdictions
      })
    },
  })

  const jurisdictionList: IJurisdiction[] = jurisdictionDataQuery?.data?.sort(
    (a: any, b: any) => a.name.localeCompare(b.name)
  )

  const licenseAttestationDataQuery = useQuery({
    queryKey: ['getLicenseAttestation', businessSelected.id],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/licenseattestation`,
        params: {
          businessId: businessSelected.id,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
    onSuccess: (data) => {
      setInitialValues({
        jurisdiction: data.map((x: any) => x.jurisdictionId),
      })
    },
  })

  const licenseData = licenseAttestationDataQuery?.data

  const inputDataFormSchema = Yup.object().shape({
    jurisdiction: Yup.array().min(1, 'Required'),
  })

  const addLicenseAttestationMutation = useMutation({
    mutationFn: async (params: any) => {
      return axios({
        method: 'post',
        url: `${BACKEND_URL}/LicenseAttestation`,
        data: params.requestData,
        headers: {
          Authorization: userToken,
        },
      })
    },
    onSuccess: async () => {
      enqueueSnackbar('Jurisdiction added', notistackSucces)
      await queryClient.refetchQueries({ queryKey: 'getLicenseAttestation' })
      await queryClient.refetchQueries({ queryKey: 'documentsRequired' })
      await queryClient.refetchQueries({ queryKey: 'uploadProgressPercent' })
      queryClient.refetchQueries({
        queryKey: 'uploadedProgressPercent',
      })
      inputDataForm.resetForm()
    },
  })

  const deleteLicenseAttestationMutation = useMutation({
    mutationFn: async (jurisdictionId: number) => {
      await axios({
        method: 'delete',
        headers: { Authorization: userToken },
        url: `${BACKEND_URL}/licenseattestation/${jurisdictionId}`,
      })
    },
    onSuccess: async () => {
      enqueueSnackbar('Jurisdiction removed', notistackSucces)
      await queryClient.refetchQueries({ queryKey: 'getLicenseAttestation' })
      await queryClient.refetchQueries({ queryKey: 'documentsRequired' })
      await queryClient.refetchQueries({ queryKey: 'uploadProgressPercent' })
      queryClient.refetchQueries({
        queryKey: 'uploadedProgressPercent',
      })
      inputDataForm.resetForm()
    },
  })

  const inputDataForm = useFormik({
    initialValues,
    validationSchema: inputDataFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      const requestData = {
        jurisdiction: values.jurisdiction || null,
        businessId: businessSelected?.id,
      }
      setJurisdictionSelected([])

      addLicenseAttestationMutation.mutate({ requestData })

      setSubmitting(false)
    },
  })

  const handleDeleteJurisdiction = (jurisdictionId: number) => {
    deleteLicenseAttestationMutation.mutate(jurisdictionId)
  }

  const getAvailableJurisdictions = () => {
    const available = jurisdictionList
      ?.filter(
        (item) => !licenseData?.some((x: any) => x.jurisdictionId === item.id)
      )
      .map((x) => {
        return {
          id: x.id,
          name: x.name,
          type: x.type,
          displayName: `${x.type}: ${x.name}`,
        }
      })

    return available?.sort((a: any, b: any) =>
      a.displayName.localeCompare(b.displayName)
    )
  }

  const getJurisdictionsSelected = () => {
    const selectedList = jurisdictionList?.filter((j) =>
      licenseData?.some((x: any) => j.id === x.jurisdictionId)
    )

    const dataList = selectedList?.map((j) => {
      const found = licenseData?.find((x: any) => j.id === x.jurisdictionId)

      return {
        id: found.id,
        type: j.type,
        name: j.name,
        displayName: `${j.type}: ${j.name}`,
      }
    })

    return dataList?.sort((a: any, b: any) =>
      a.displayName.localeCompare(b.displayName)
    )
  }

  const renderJurisdiction = () => {
    const handleChange = (value: any) => {
      setJurisdictionSelected(value)
      inputDataForm.setFieldValue('jurisdiction', value)
    }

    const data = jurisdictionList ? getAvailableJurisdictions() : []

    return (
      <MultipleSelectionDropDown
        id="input-Jurisdiction"
        label="Jurisdiction"
        data={data}
        disable={false}
        selectionState={jurisdictionSelected}
        handleSelectionChange={handleChange}
        separator=":"
        displayProperties={['type', 'name']}
      />
    )
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={onCloseDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        maxWidth="md"
      >
        <DialogTitleStyled id="confirm-dialog-title">
          <Typography
            variant="body1"
            style={{
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 400,
              lineHeight: 1.2,
              textAlign: 'center',
            }}
          >
            {`License ${isUk ? '' : 'Attestation'}`}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseDialog}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  style={{ width: '300px' }}
                >
                  {jurisdictionList && renderJurisdiction()}
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <form onSubmit={inputDataForm.handleSubmit}>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      autoFocus
                      disabled={
                        licenseAttestationDataQuery.isLoading ||
                        addLicenseAttestationMutation.isLoading ||
                        deleteLicenseAttestationMutation.isLoading
                      }
                    >
                      Add
                    </Button>
                  </form>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="h6" component="div" color="primary">
                Submitted
              </Typography>
              <List
                dense={true}
                style={{
                  minHeight: '400px',
                  maxHeight: '100%',
                  overflow: 'auto',
                  width: '380px',
                  border: '1px solid lightgrey',
                }}
              >
                {licenseAttestationDataQuery.isLoading && (
                  <>
                    {[0, 1, 2, 3, 4, 5].map((x) => (
                      <ListItem key={x}>
                        <Skeleton height={35} width={330} />
                      </ListItem>
                    ))}
                  </>
                )}
                {getJurisdictionsSelected()?.map((x: any) => (
                  <ListItem key={x.id}>
                    <ListItemText primary={x.displayName} />
                    <ListItemIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDeleteJurisdiction(x.id)}
                    >
                      <Delete />
                    </ListItemIcon>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default LicenseAttestationForm
