interface IColors {
  primary: string
  secondary: string
  blue: string
  darkGray: string
  mediumGray: string
  gray: string
  lightGray: string
  white: string
  black: string
  success: string
  error: string
  info: string
  warning: string
}

export const Colors: IColors = {
  primary: '#002E3A',
  secondary: '#E7F7FF',
  blue: '#82C2E1',
  darkGray: '#757575',
  mediumGray: '#CCCCCC',
  gray: '#EBEBEB',
  lightGray: '#F5F5F5',
  white: '#FFFFFF',
  black: '#000000',
  success: '#388E3C',
  error: '#E03F3F',
  warning: '#FFA500',
  info: '#FFD700',
}
