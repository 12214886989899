import React, { useContext } from 'react'
import { Switch, Route } from 'react-router-dom'

import { AuthContext } from 'src/context/AuthenticationContext'
import { AuthProvider as DSSProvider } from 'everchain-digital-forms'
import { ACCESSDENIED, HOME } from './routes'

import { SignIn } from './components/Authentication/SignIn'
import { SignInCallback } from './components/Authentication/SignInCallback'
import { SignInSilent } from './components/Authentication/SignInSilent'
import { SignOutCallback } from './components/Authentication/SignOutCallback'

import '@progress/kendo-theme-material/dist/all.css'
import SwitchRouteProtect from './SwitchRouteProtect'
import AccessDenied from './pages/AccessDenied'
import { PasswordExpiringNotification } from './components/PasswordExpiringNotification'

const App: React.FC = () => {
  const currentContext = useContext(AuthContext)
  return (
    <DSSProvider {...currentContext}>
      <Switch>
        <Route exact path={HOME}>
          <SignIn />
        </Route>
        <Route exact path="/signin-callback">
          <SignInCallback />
        </Route>
        <Route exact path="/signin-silent">
          <SignInSilent />
        </Route>
        <Route exact path="/signout-callback">
          <SignOutCallback />
        </Route>
        <Route exact path={ACCESSDENIED}>
          <AccessDenied />
        </Route>
        <Route>
          <PasswordExpiringNotification>
            <SwitchRouteProtect />
          </PasswordExpiringNotification>
        </Route>
      </Switch>
    </DSSProvider>
  )
}

export default App
