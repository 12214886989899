import React, { useContext } from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import { AuthContext } from 'src/context/AuthenticationContext'
import { isUkCountry } from 'src/utils/common'
import { FooterBox, TypographyBox, CustomTypography } from './styles'

const Footer = () => {
  const { profileClient } = useContext(AuthContext)
  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )

  return (
    <footer>
      <FooterBox>
        <Container maxWidth="xl">
          <Grid>
            <Box display="flex" flexDirection="column">
              <Box display="flex" justifyContent="center">
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box>
                    <Link
                      href="https://www.everchain.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/img/ec-color-logo.svg"
                        title="EverChain"
                        width="90px"
                        alt="EverChain"
                      />
                    </Link>
                  </Box>
                  <CustomTypography>
                    EverChain &copy; {new Date().getFullYear()}
                  </CustomTypography>
                </Box>
              </Box>
              {isUk ? (
                <Box>
                  <TypographyBox>
                    <CustomTypography>
                      Use of this web site is governed by the applicable
                      services agreement and is limited to authorised users.
                    </CustomTypography>
                    <CustomTypography>
                      Please see EverChain's&nbsp;
                      <Link
                        href="https://everchain.net/privacy-policy/"
                        rel="noopener noreferrer"
                      >
                        UK Privacy Policy
                      </Link>
                      &nbsp;to learn more.
                    </CustomTypography>
                  </TypographyBox>
                  <TypographyBox>
                    <CustomTypography>
                      U.S. and International Patents Pending
                    </CustomTypography>
                  </TypographyBox>
                </Box>
              ) : (
                <Box>
                  <TypographyBox>
                    <CustomTypography>
                      Use of this web site is governed by the applicable
                      services agreement and is limited to authorized users.
                      Please see EverChain's&nbsp;
                      <Link
                        href="https://www.everchain.com/privacy-policy"
                        rel="noopener noreferrer"
                        style={{ fontSize: '0.6rem' }}
                      >
                        United States Disclaimer & Privacy Policy
                      </Link>
                      {!process.env.REACT_APP_HIDE_CANADIAN_POLICY && (
                        <>
                          &nbsp;or&nbsp;
                          <Link
                            href="https://www.everchain.com/canadian-disclaimer-privacy-policy/"
                            rel="noopener noreferrer"
                          >
                            Canadian Privacy Policy
                          </Link>
                        </>
                      )}
                      &nbsp;to learn more. U.S. and International Patents
                      Pending.
                    </CustomTypography>
                  </TypographyBox>
                </Box>
              )}
            </Box>
          </Grid>
        </Container>
      </FooterBox>
    </footer>
  )
}

export default Footer
