import { DOCUMENT_REGISTRATION } from 'src/routes'
import {
  renderClickableNumberLink,
  renderDate,
  renderNumber,
  renderProgress,
  renderReason,
} from 'src/utils/formatKendoColumns'

export const getColumns = (type: string, cardType: string) => {
  const columnsToRetrieve: any[] = []

  if (type === 'Certification' || type === 'Re-Certification') {
    columnsToRetrieve.push(
      {
        field: 'businessId',
        title: 'BusinessId',
        show: false,
      },
      {
        field: 'businessName',
        title: 'Business',
        show: true,
        cell: (props: any) =>
          renderClickableNumberLink(props, `${DOCUMENT_REGISTRATION}`, () => {
            localStorage.setItem(
              'certificationManagerBusiness',
              props.dataItem['businessId']
            )
          }),
      },
      {
        field: 'analystId',
        title: 'AnalystId',
        show: false,
      },
      {
        field: 'analystName',
        title: 'Analyst',
        show: true,
      },
      {
        field: 'daysInCertification',
        title: 'Days In Certification',
        cell: renderNumber,
        alignRight: true,
        show: true,

        filter: 'numeric',
      },
      {
        field: 'startDate',
        title: 'Start Date',
        cell: renderDate,
        show: true,
        filter: 'date',
      },
      {
        field: 'deadline',
        title: 'DeadLine',
        cell: renderDate,
        show: ['currently-in-certification', 'late-certifications'].includes(
          cardType
        ),
        filter: 'date',
      },
      {
        field: 'endDate',
        title: 'End Date',
        cell: renderDate,
        show: ['approved-certifications', 'failed-certifications'].includes(
          cardType
        ),
        filter: 'date',
      },
      {
        field: 'lateForDays',
        title: 'Late for (Days)',
        cell: renderNumber,
        alignRight: true,
        show: ['late-certifications'].includes(cardType),
        filter: 'numeric',
      },
      {
        field: 'documentsUploaded',
        title: '% Uploaded',
        cell: renderProgress,
        alignRight: true,
        show: ['currently-in-certification', 'late-certifications'].includes(
          cardType
        ),
      },
      {
        field: 'documentsApproved',
        title: '% Approved',
        cell: renderProgress,
        alignRight: true,
        show: ['currently-in-certification', 'late-certifications'].includes(
          cardType
        ),
      },
      {
        field: 'rejectReason',
        title: 'Rejection Reason',
        cell: renderReason,
        alignRight: true,
        show: ['failed-certifications'].includes(cardType),
      }
    )
  }
  return columnsToRetrieve
}
