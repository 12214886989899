import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemIcon,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core'
import React from 'react'

interface MultipleSelectionDropDownProps {
  id: string
  label: string
  data: any[]
  disable: boolean
  selectionState: any
  handleSelectionChange: (item: any, event?: any) => void
  hasValidationError?: boolean
  errorMessage?: string
  customSelectionItemName?: string
  customSelectionData?: string[]
  customSelectedMessage?: string
  selectAll?: boolean
  separator?: string
  displayProperties?: string[]
}

const useStyles = makeStyles(() => ({
  formControl: {
    width: 500,
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
}))

const MenuProps: any = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  variant: 'menu',
}

const MultipleSelectionDropDown: React.FC<MultipleSelectionDropDownProps> = ({
  id,
  label,
  data,
  disable,
  selectionState,
  handleSelectionChange,
  hasValidationError,
  errorMessage,
  customSelectionItemName,
  customSelectionData,
  customSelectedMessage,
  selectAll,
  separator,
  displayProperties,
}: MultipleSelectionDropDownProps) => {
  const classes = useStyles()

  const isAllItemsSelected =
    data && data.length > 0 && selectionState.length === data.length

  const handleChange = (event: any) => {
    const { value } = event.target

    if (value[value.length - 1] === 'all') {
      handleSelectionChange(
        selectionState.length === data.length ? [] : data.map((d) => d.id),
        event
      )
      return
    }

    if (value[value.length - 1] === 'custom_selection') {
      handleSelectionChange(
        selectionState.length === customSelectionData?.length
          ? []
          : customSelectionData,
        event
      )
      return
    }

    handleSelectionChange(value, event)
  }

  const getItemSelectedLabel = (): string | null => {
    if (isAllItemsSelected) return ' (All items have been selected)'

    if (selectionState.length === 0) return null
    if (selectionState.length > 1)
      return ` (${selectionState.length} items selected)`
    if (selectionState.length === 1) return ' (1 item selected)'

    return null
  }

  return (
    <FormControl disabled={disable}>
      <InputLabel
        id="mutiple-select-label"
        style={{ width: 'max-content', maxWidth: '600px' }}
      >
        {label}
        {getItemSelectedLabel()}
      </InputLabel>
      <Select
        labelId="mutiple-select-label"
        multiple
        value={selectionState}
        onChange={handleChange}
        renderValue={(selected: any) => {
          return displayProperties
            ? data
                ?.filter((x: any) => selected.includes(x.id))
                .map((x) =>
                  displayProperties.length > 1
                    ? `${x[displayProperties[0]]} ${separator} ${
                        x[displayProperties[1]]
                      }`
                    : `${x[displayProperties[0]]}`
                )
                .join(', ')
            : selected.join(', ')
        }}
        MenuProps={MenuProps}
        style={{ width: '300px' }}
        disabled={!data}
        error={hasValidationError}
      >
        {selectAll && data && data.length > 0 && (
          <MenuItem
            value="all"
            classes={{
              root: isAllItemsSelected ? classes.selectedAll : '',
            }}
          >
            <ListItemIcon>
              <Checkbox checked={isAllItemsSelected} />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.selectAllText }}
              primary="Select All"
            />
          </MenuItem>
        )}

        {data &&
          data.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              <ListItemIcon>
                <Checkbox checked={selectionState.indexOf(item.id) > -1} />
              </ListItemIcon>
              <ListItemText
                style={{ maxWidth: '600px' }}
                primary={
                  displayProperties
                    ? displayProperties.length > 1
                      ? `${item[displayProperties[0]]} ${separator} ${
                          item[displayProperties[1]]
                        }`
                      : `${item[displayProperties[0]]}`
                    : item
                }
                primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
              />
            </MenuItem>
          ))}
      </Select>
      {hasValidationError && (
        <FormHelperText error={hasValidationError}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  )
}

MultipleSelectionDropDown.defaultProps = {
  hasValidationError: undefined,
  errorMessage: undefined,
  customSelectionItemName: undefined,
  customSelectionData: undefined,
  customSelectedMessage: undefined,
  selectAll: true,
  separator: undefined,
  displayProperties: undefined,
}

export default MultipleSelectionDropDown
