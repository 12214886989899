import React from 'react'
import { Grid, TextField } from '@material-ui/core'
import { maskPhoneNumber } from 'src/utils/masker'

interface ICollectionAgencyManager {
  handleChange: (name: any, value: any) => void
  inputDataForm: any
  readOnly: boolean
}

const CollectionAgencyManager: React.FC<ICollectionAgencyManager> = ({
  handleChange,
  inputDataForm,
  readOnly,
}: ICollectionAgencyManager) => {
  return (
    <>
      <Grid
        container
        direction="row"
        style={{ margin: '6px 6px 6px 6px', width: '800px' }}
        spacing={3}
      >
        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-cy="dialog-input-field-fullname"
            label="Full Name"
            name="fullName"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value)
            }}
            value={inputDataForm.values.fullName}
            error={!!inputDataForm.errors.fullName}
            helperText={inputDataForm.errors.fullName}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-cy="dialog-input-field-title"
            label="Title"
            name="title"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              maxLength: 100,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value)
            }}
            value={inputDataForm.values.title}
            error={!!inputDataForm.errors.title}
            helperText={inputDataForm.errors.title}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-cy="dialog-input-field-yearsOfExperience"
            label="Years of Experience"
            type="number"
            name="yearsOfExperience"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: 0,
              max: 99,
              maxLenght: 2,
            }}
            onChange={({ target: { value, name } }) => {
              if (isNaN(value as any) || Number(value) > 99) return
              handleChange(name, Number(value).toString().substring(0, 2))
            }}
            value={inputDataForm.values.yearsOfExperience || ''}
            error={!!inputDataForm.errors.yearsOfExperience}
            helperText={inputDataForm.errors.yearsOfExperience}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly}
            fullWidth
            type="number"
            data-cy="dialog-input-field-yearsWithYourCompany"
            label="Years With Your Company"
            name="yearsWithYourCompany"
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              min: 0,
              max: 99,
              maxLenght: 2,
            }}
            onChange={({ target: { value, name } }) => {
              if (isNaN(value as any) || Number(value) > 99) return
              handleChange(name, Number(value).toString().substring(0, 2))
            }}
            value={inputDataForm.values.yearsWithYourCompany || ''}
            error={!!inputDataForm.errors.yearsWithYourCompany}
            helperText={inputDataForm.errors.yearsWithYourCompany}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-cy="dialog-input-field-telephone"
            label="Telephone"
            name="telephone"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target: { value, name } }) => {
              const masked = maskPhoneNumber(value)
              handleChange(name, masked)
            }}
            placeholder="(000) 000-0000"
            value={inputDataForm.values.telephone}
            error={!!inputDataForm.errors.telephone}
            helperText={inputDataForm.errors.telephone}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            disabled={readOnly}
            fullWidth
            data-cy="dialog-input-field-emailAddress"
            label="Email Address"
            name="emailAddress"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={({ target: { value, name } }) => {
              handleChange(name, value)
            }}
            value={inputDataForm.values.emailAddress}
            error={!!inputDataForm.errors.emailAddress}
            helperText={inputDataForm.errors.emailAddress}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default CollectionAgencyManager
