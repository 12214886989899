/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { Link as LinkMaterial, Tooltip, Typography } from '@material-ui/core'

import { Link } from 'react-router-dom'
import {
  formatDate,
  formatDateAndTimeLocal,
  getLocalDatetimer,
  getLocaleDateTimeString,
} from './date'
import {
  ConvertPostSaleRequestStatusToDisplay,
  ConvertSOPStatus,
} from './nameConvertions'
import { numberToPercentage } from './numbers'
import { capitalizeAllText } from './text'

export const renderEscalated = (props: any) => {
  return <td>{props.dataItem[props.field] ? 'Yes' : 'No'}</td>
}

export const renderStatus = (props: any) => {
  return (
    <td>
      {ConvertPostSaleRequestStatusToDisplay(props.dataItem[props.field])}
    </td>
  )
}

export const renderTextCenter = (props: any) => {
  return <td style={{ textAlign: 'center' }}>{props.dataItem[props.field]}</td>
}

export const renderDate = (props: any) => {
  return (
    <td>
      {props.dataItem[props.field]
        ? formatDate(new Date(props.dataItem[props.field]))
        : props.dataItem[props.field]}
    </td>
  )
}

export const renderSOPStatus = (props: any) => {
  return <td>{ConvertSOPStatus(props.dataItem['status'])}</td>
}

export const renderDateTime = (props: any) => {
  return (
    <td>
      {props.dataItem[props.field]
        ? formatDateAndTimeLocal(
            new Date(props.dataItem[props.field]),
            getLocaleDateTimeString()
          )
        : props.dataItem[props.field]}
    </td>
  )
}

const convertToJsonString = (value: string) => {
  let result = ''

  const jsonObject = JSON.parse(value)

  for (const key in jsonObject) {
    // eslint-disable-next-line no-prototype-builtins
    if (jsonObject.hasOwnProperty(key)) {
      result += `${key}: ${jsonObject[key]} | `
    }
  }

  // Remove the trailing ' | ' and return the formatted string
  return result.slice(0, -3)
}

export const renderJson = (props: any) => {
  return <td>{convertToJsonString(props.dataItem[props.field])}</td>
}

export const renderNumber = (props: any) => {
  return <td style={{ textAlign: 'right' }}>{props.dataItem[props.field]}</td>
}

export const renderPercent = (props: any) => {
  return (
    <td style={{ textAlign: 'right' }}>
      {numberToPercentage(props.dataItem[props.field])}
    </td>
  )
}

export const renderProgress = (props: any) => {
  return (
    <td {...props} style={{ textAlign: 'right' }}>
      {props.dataItem[props.field] !== null &&
      !isNaN(props.dataItem[props.field])
        ? `${parseFloat(props.dataItem[props.field]).toFixed(0)}%`
        : '0%'}
    </td>
  )
}

export const renderReason = (props: any) => {
  return (
    <td {...props} style={{ textAlign: 'left' }}>
      {props.dataItem[props.field] ? (
        props.dataItem[props.field].length > 30 ? (
          <Tooltip title={props.dataItem[props.field]}>
            <Typography>
              {`${props.dataItem[props.field].substring(0, 30)}...`}
            </Typography>
          </Tooltip>
        ) : (
          props.dataItem[props.field]
        )
      ) : (
        ''
      )}
    </td>
  )
}

export const renderCapitalize = (props: any) => {
  return <td>{capitalizeAllText(props.dataItem[props.field])}</td>
}

export const renderRemaining = (props: any) => {
  const { days, hours } = getLocalDatetimer(props.dataItem[props.field])
  return <td>{days >= 0 ? `(${days} Days, ${hours} Hours)` : ''}</td>
}

export const renderClickableNumberLink = (
  props: any,
  link: any,
  onClick?: any,
  alignment: any = 'left'
) => {
  return (
    <td onClick={onClick} style={{ textAlign: alignment }}>
      <LinkMaterial
        color="primary"
        variant="body2"
        component={Link}
        to={`${link}`}
        style={{ cursor: 'pointer' }}
      >
        <Typography style={{ width: '100%' }}>
          {props.dataItem[props.field]}
        </Typography>
      </LinkMaterial>
    </td>
  )
}
