import { Box, Typography } from '@material-ui/core'
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react'

interface PageTitleProps {
  title: string
  setTitle: Dispatch<SetStateAction<string>>
}

const PageTitleContext = createContext<PageTitleProps>({
  title: '',
  setTitle: () => {},
})

interface IPageTitle {
  children: React.ReactNode
}

export const usePageTitleContext = () => useContext(PageTitleContext)

const PageTitle: React.FC<IPageTitle> = ({ children }) => {
  const [title, setTitle] = useState('')

  return (
    <PageTitleContext.Provider value={{ title, setTitle }}>
      <Box marginBottom={5} marginTop="45px">
        <Typography variant="h2">{title}</Typography>
      </Box>
      {children}
    </PageTitleContext.Provider>
  )
}

export default PageTitle
