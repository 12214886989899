/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  Paper,
} from '@material-ui/core'

const styles = {
  paper: {
    width: 300,
    height: 400,
    overflow: 'auto',
  },
}

function not(a: any[], b: any[]): any[] {
  return a.filter(
    (value) => b.findIndex((itemB) => itemB.id === value.id) === -1
  )
}

function intersection(a: any[], b: any[]): any[] {
  return a.filter(
    (value) => b.findIndex((itemB) => itemB.id === value.id) !== -1
  )
}

interface TransferListProps {
  loading?: boolean
  leftData: any[]
  rightData?: any[]
  showAllButtons?: boolean
  onSendLeftColumn: (items: any[]) => void
  onSendRightColumn: (items: any[]) => void
  readonly?: boolean
}

const TransferList: React.FC<TransferListProps> = ({
  loading,
  leftData,
  rightData,
  showAllButtons,
  onSendLeftColumn,
  onSendRightColumn,
  readonly,
}) => {
  const [checked, setChecked] = useState<any[]>([])
  const [left, setLeft] = useState<any[]>([])
  const [right, setRight] = useState<any[]>([])

  const handleSendLeftItems = (items: any[]) => {
    onSendLeftColumn(items)
  }

  const handleSendRighItems = (items: any[]) => {
    onSendRightColumn(items)
  }

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = (value: any) => (): void => {
    if (!readonly) {
      const currentIndex = checked.findIndex(
        (checkItem) => checkItem.id === value.id
      )
      const newChecked = [...checked]

      if (currentIndex === -1) {
        newChecked.push(value)
      } else {
        newChecked.splice(currentIndex, 1)
      }

      setChecked(newChecked)
    }
  }

  const handleCheckedRight = () => {
    handleSendRighItems(leftChecked)
    setRight(
      right.concat(leftChecked).sort((a, b) => a.name.localeCompare(b.name))
    )
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    handleSendLeftItems(rightChecked)
    setLeft(
      left.concat(rightChecked).sort((a, b) => a.name.localeCompare(b.name))
    )
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const handleAllRight = () => {
    handleSendRighItems(left)
    setRight(right.concat(left).sort((a, b) => a.name.localeCompare(b.name)))
    setLeft([])
  }

  const handleAllLeft = () => {
    handleSendLeftItems(right)
    setLeft(left.concat(right).sort((a, b) => a.name.localeCompare(b.name)))
    setRight([])
  }

  useEffect(() => {
    if (left.length !== 0 || right.length !== 0) return

    if (leftData.length) {
      setLeft(leftData)
    }
    if (rightData?.length) {
      setRight(rightData)
    }
  }, [leftData, rightData, left, right])

  useEffect(() => {
    return () => {
      setLeft([])
      setRight([])
      setChecked([])
    }
  }, [])

  const customList = (items: any[]) => (
    <Paper style={styles.paper}>
      {loading ? (
        [0, 1, 2, 3].map((item) => (
          <Box key={item} my={2} p={3}>
            {/* <Skeleton variant="rectangular" height={20} /> */}
          </Box>
        ))
      ) : (
        <List dense component="div" role="list">
          {items.map((value) => {
            const labelId = `transfer-list-item-${value.name}-label`
            return (
              <ListItem
                key={value.id}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  {!readonly && (
                    <Checkbox
                      checked={
                        checked.findIndex(
                          (checkItem) => checkItem.id === value.id
                        ) !== -1
                      }
                      tabIndex={-1}
                      disableRipple
                      color="primary"
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  )}
                </ListItemIcon>
                <ListItemText id={labelId} secondary={value.name} />
              </ListItem>
            )
          })}
          <ListItem />
        </List>
      )}
    </Paper>
  )

  return (
    <Grid
      container
      spacing={2}
      justifyContent="flex-start"
      alignItems="center"
      style={{
        flexWrap: 'nowrap',
      }}
    >
      <Grid item>{customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          {showAllButtons && (
            <Button
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={left.length === 0 || readonly}
              aria-label="move all right"
            >
              ≫
            </Button>
          )}
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0 || readonly}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0 || readonly}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          {showAllButtons && (
            <Button
              variant="outlined"
              size="small"
              onClick={handleAllLeft}
              disabled={right.length === 0 || readonly}
              aria-label="move all left"
            >
              ≪
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </Grid>
  )
}

TransferList.defaultProps = {
  rightData: [],
  loading: false,
  showAllButtons: true,
}
export default TransferList
