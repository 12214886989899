import { WebStorageStateStore } from 'oidc-client'

export const clientId = '10386ef9-fdf8-428d-b651-57421fd3c4d7'
export const appUrl = window.location.origin
export const authUrl = process.env.REACT_APP_AUTHORITY_URL

export const IDENTITY_SETTINGS: Oidc.UserManagerSettings = {
  authority: authUrl,
  client_id: clientId,
  redirect_uri: `${appUrl}/signin-callback`,
  post_logout_redirect_uri: `${appUrl}/signout-callback`,
  silent_redirect_uri: `${appUrl}/signin-silent`,
  response_type: 'code',
  scope: 'openid profile external',
  userStore: new WebStorageStateStore({
    store: window.localStorage,
    prefix: 'dt.',
  }),
  staleStateAge: 2, // After 2 seconds the states in the application storage will be considered
  // stale and will be cleaned when the clearStaleState function is called(LogoutCallback)
  accessTokenExpiringNotificationTime: 60,
  metadata: {
    issuer: authUrl,
    authorization_endpoint: `${authUrl}/connect/authorize`,
    userinfo_endpoint: `${authUrl}/connect/userinfo`,
    end_session_endpoint: `${authUrl}/connect/endsession`,
    jwks_uri: `${authUrl}/.well-known/openid-configuration/jwks`,
    check_session_iframe: `${authUrl}/connect/checksession`,
    introspection_endpoint: `${authUrl}/connect/introspect`,
    revocation_endpoint: `${authUrl}/connect/revocation`,
    token_endpoint: `${authUrl}/connect/token`,
  },
}
