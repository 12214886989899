import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import axios from 'axios'
import { format, isSameDay } from 'date-fns'
import React, { useContext } from 'react'
import { useQuery } from 'react-query'
import { BACKEND_URL } from 'src/api/axios/api'
import { GetAccessToken } from 'src/api/axios/helper'
import { formatDateAndTimeLocal } from 'src/utils/date'
import { CertificationPeriodContext } from 'src/context/CertificationPeriodContext'
import { stringAvatar } from 'src/utils/common'

interface HistoryMainProps {
  documentRequestId: number
}

const getDateLabel = (date: string) => {
  const commentDate = new Date(date)
  const localDate = new Date()

  if (isSameDay(commentDate, localDate)) {
    return format(commentDate, 'p')
  }

  return formatDateAndTimeLocal(commentDate)
}

function stringToColor(string: string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

const HistoryMain: React.FC<HistoryMainProps> = ({
  documentRequestId,
}: HistoryMainProps) => {
  const userToken = GetAccessToken()
  const certificationPeriodSelected = useContext(CertificationPeriodContext)

  const queryHistoryData = useQuery({
    enabled: !!documentRequestId,
    queryKey: ['getHistory', documentRequestId],
    queryFn: async () =>
      axios({
        method: 'get',
        url: `${BACKEND_URL}/businessuploadhistory/${documentRequestId}`,
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      }),
    refetchInterval: 30000,
  })

  const historyData = queryHistoryData?.data

  if (certificationPeriodSelected.id !== null && !historyData) {
    return (
      <Box display="flex" flexDirection="column">
        <Typography>History log is empty</Typography>
      </Box>
    )
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box height={450}>
        <List style={{ maxHeight: '100%', overflow: 'auto' }}>
          {queryHistoryData.isLoading && (
            <>
              <Skeleton height={80} width="95%" />
              <Skeleton height={80} width="95%" />
              <Skeleton height={80} width="95%" />
              <Skeleton height={80} width="95%" />
            </>
          )}
          {historyData?.map((item: any) => {
            return (
              <div key={`div_${item.id}`}>
                <ListItem alignItems="flex-start" key={`item_${item.id}`}>
                  <ListItemAvatar key={`avatar_${item.id}`}>
                    <Avatar {...stringAvatar(item.userName)} />
                  </ListItemAvatar>
                  <ListItemText
                    key={`text_${item.id}`}
                    primary={`${item.userName} - ${getDateLabel(
                      item.creationDateUtc
                    )}`}
                    secondary={item.action}
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </div>
            )
          })}
        </List>
      </Box>
    </Box>
  )
}

export default HistoryMain
