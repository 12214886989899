import { format } from 'date-fns'
import { useContext } from 'react'
import { AuthContext } from 'src/context/AuthenticationContext'

const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

interface Map {
  [key: string]: string
}

const ProfileClient = () => {
  const { profileClient } = useContext(AuthContext)
  return profileClient
}

export const getLanguage = (): string => {
  return getLanguageFromCountry(
    ProfileClient()?.Country || process.env.REACT_APP_COUNTRY
  )
}

export const getLanguageFromCountry = (country: string): string => {
  if (country === 'US') return 'en-us'
  if (country === 'UK') return 'en-gb'
  if (country === 'CA') return 'en-ca'

  return 'en-us'
}

export const getLocaleDateString = (
  language?: string | undefined,
  toDisplay?: boolean | undefined
): string => {
  const formats: Map = {
    'fr-ca': 'dd/MM/yyyy',
    'en-ca': 'dd/MM/yyyy',
    'en-gb': 'dd/MM/yyyy',
    'en-gb-oxendict': 'dd/MM/yyyy',
    'en-us': 'MM/dd/yyyy',
  }
  if (toDisplay)
    return (
      formats[language ?? getLanguage()]?.toLocaleLowerCase() || 'yyyy/mm/dd'
    )
  return formats[language ?? getLanguage()] || 'yyyy/MM/dd'
}

export const getLocaleDateTimeString = (
  includeSeconds: boolean = false,
  splitTime: boolean = false
): string => {
  const formats: Map = {
    'fr-ca': `dd/MM/yyyy HH:mm${includeSeconds ? ':ss' : ''}`,
    'en-ca': `dd/MM/yyyy HH:mm${includeSeconds ? ':ss' : ''}`,
    'en-gb': `dd/MM/yyyy HH:mm${includeSeconds ? ':ss' : ''}`,
    'en-us': `M/d/yyyy h:mm${includeSeconds ? ':ss' : ''} a`,
  }
  return (
    formats[getLanguage()] ||
    (includeSeconds
      ? splitTime
        ? 'yyyy/MM/dd | h:mm:ss a'
        : 'yyyy/MM/dd h:mm:ss a'
      : splitTime
      ? 'yyyy/MM/dd | h:mm a'
      : 'yyyy/MM/dd h:mm a')
  )
}

export const formatDate = (
  date: Date | number | string = Date.now(),
  formatType: string = getLocaleDateString()
): string => {
  return format(new Date(date), formatType)
}

export const formatDateAndTime = (
  date: Date | number | string = Date.now(),
  formatType: string | undefined = undefined,
  includeSeconds: boolean = false,
  splitTime: boolean = false
): string => {
  const f = formatType || getLocaleDateTimeString(includeSeconds, splitTime)
  return format(new Date(date), f)
}

export const formatDateAndTimeLocal = (
  date: Date,
  formatType: string | undefined = undefined,
  includeSeconds: boolean = false,
  splitTime: boolean = false
): string => {
  const f = formatType || getLocaleDateTimeString(includeSeconds, splitTime)
  return format(new Date(date), f)
}

interface GetLocalDateProps {
  days: number
  hours: number
  minutes: number
  seconds: number
}
export const getLocalDatetimer = (
  deadline: string | Date
): GetLocalDateProps => {
  if (!deadline) {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }

  const dateNow = new Date().getTime()
  const time = new Date(deadline).getTime() - dateNow

  const days = Math.floor(time / (1000 * 60 * 60 * 24))
  const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((time % (1000 * 60)) / 1000)
  return {
    days,
    hours,
    minutes,
    seconds,
  }
}

export const FormatNumberToDoubleDigit = (n: number): string => {
  return n.toLocaleString(navigator.language, {
    minimumIntegerDigits: 2,
    useGrouping: false,
  })
}

const AdjustObservanceDay = (holidayDate: Date): Date => {
  const weekDay = holidayDate.getDay()

  switch (weekDay) {
    case 6: // If a Saturday, Friday is a holiday
      holidayDate.setDate(holidayDate.getDate() - 1)
      return holidayDate
    case 0: // If a Sunday, Monday is a holiday
      holidayDate.setDate(holidayDate.getDate() + 1)
      return holidayDate
    default:
      // If is not on the weekend, dont adjust the date
      return holidayDate
  }
}

export const GetHolidays = (): Array<any> => {
  const result: Array<any> = []

  const d = new Date()
  let year = d.getFullYear()

  // Current Year
  // Fixed date holidays
  result.push(AdjustObservanceDay(new Date(year, 0, 1))) // New Year's Day
  result.push(AdjustObservanceDay(new Date(year, 0, 21))) // MLK Day
  result.push(AdjustObservanceDay(new Date(year, 6, 4))) // Independence Day
  result.push(AdjustObservanceDay(new Date(year, 10, 11))) // Veteran's Day
  result.push(AdjustObservanceDay(new Date(year, 11, 25))) // Christmas

  // Floating date holidays
  result.push(FindDate(3, 'Monday', 1, year)) // President's Day: 3rd monday in February
  result.push(FindDateOfLastWeekDay('Monday', 4, year)) // Memorial Day: Last monday in May
  result.push(FindDate(1, 'Monday', 8, year)) // Labor Day: 1st monday in September
  result.push(FindDate(2, 'Monday', 9, year)) // Columbus Day: 2nd monday in October
  result.push(FindDateOfLastWeekDay('Thursday', 10, year)) // Thanksgiving Day: Last thursday in November
  result.push(FindDateOfLastWeekDay('Friday', 10, year)) // Thanksgiving Friday: Last friday in November

  // Next Year
  year += 1

  // Fixed date holidays
  result.push(AdjustObservanceDay(new Date(year, 0, 1))) // New Year's Day
  result.push(AdjustObservanceDay(new Date(year, 0, 21))) // MLK Day
  result.push(AdjustObservanceDay(new Date(year, 6, 4))) // Independence Day
  result.push(AdjustObservanceDay(new Date(year, 10, 11))) // Veteran's Day
  result.push(AdjustObservanceDay(new Date(year, 11, 25))) // Christmas

  // Floating date holidays
  result.push(FindDate(3, 'Monday', 1, year)) // President's Day: 3rd monday in February
  result.push(FindDateOfLastWeekDay('Monday', 4, year)) // Memorial Day: Last monday in May
  result.push(FindDate(1, 'Monday', 8, year)) // Labor Day: 1st monday in September
  result.push(FindDate(2, 'Monday', 9, year)) // Columbus Day: 2nd monday in October
  result.push(FindDateOfLastWeekDay('Thursday', 10, year)) // Thanksgiving Day: Last thursday in November
  result.push(FindDateOfLastWeekDay('Friday', 10, year)) // Thanksgiving Friday: Last friday in November

  return result
}

const FindDateOfLastWeekDay = (
  lastWeekDay: string,
  month: number,
  year: number
): Date => {
  const dayOfTheWeek = weekDays.findIndex((element) => element === lastWeekDay)

  const d = new Date(year, month + 1, 0)
  const dayOfTheWeekFound = false

  while (!dayOfTheWeekFound) {
    if (d.getDay() !== dayOfTheWeek) d.setDate(d.getDate() - 1)
    else return d
  }

  throw new Error('Unable to find last week day')
}

const FindDate = (
  weekNumber: number,
  weekDay: string,
  month: number,
  year: number
): Date => {
  const dayOfTheWeek = weekDays.findIndex((element) => element === weekDay)
  const d = new Date(year, month, 1)
  let foundFirstWeekDay = false

  while (!foundFirstWeekDay) {
    // Finding the first occurency of the target week day
    if (d.getDay() !== dayOfTheWeek) d.setDate(d.getDate() + 1)
    else foundFirstWeekDay = true
  }

  // If the holiday is in the
  switch (weekNumber) {
    case 1: // First week
      return d
    case 2: // Second week
      d.setDate(d.getDate() + 7)
      return d
    case 3: // Third week
      d.setDate(d.getDate() + 14)
      return d
  }

  throw new Error('Unable to find date')
}

export const IsHoliday = (date: Date): boolean => {
  const holidays: Array<any> = GetHolidays()
  const found = holidays.find((element) => element.getTime() === date.getTime())

  return found
}

export const IsBusinessDay = (date: any): boolean => {
  const d = new Date(date)
  // Exclude weekends
  const day = d.getDay()
  if (day === 0 || day === 6) {
    return false
  }
  // Check for holiday
  return !IsHoliday(d)
}
