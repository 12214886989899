import { Box } from '@material-ui/core'
import { GridColumnMenuFilter } from '@progress/kendo-react-grid'
import React from 'react'

export const ColumnMenu = (props: any): React.ReactElement => {
  return (
    <Box>
      <GridColumnMenuFilter {...props} expanded={true} />
    </Box>
  )
}
