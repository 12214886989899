import {
  Box,
  Grid,
  MenuItem,
  TextField,
  Paper,
  Button,
} from '@material-ui/core'
import axios from 'axios'
import {
  GridCellProps,
  GridColumn as KendoGridColumn,
} from '@progress/kendo-react-grid'
import { StyledKendoGrid as KendoGrid } from 'src/styles/kendoGridStyle'
import React, { useContext, useRef, useState } from 'react'
import { BACKEND_URL } from 'src/api/axios/api'
import { GetAccessToken } from 'src/api/axios/helper'
import { getBusinessesQuery } from 'src/api/operations/get/business'
import { AuthContext } from 'src/context/AuthenticationContext'
import { formatDateAndTimeLocal } from 'src/utils/date'
import { BusinessContext } from 'src/context/BusinessContext'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { GetApp } from '@material-ui/icons'
import { ColumnMenu } from 'src/components/Filters/HeaderFilter'
import { State } from '@progress/kendo-data-query'

import { useQuery } from 'react-query'
import Skeleton from '@material-ui/lab/Skeleton'
import DocumentDetail from '../../components/DocumentCard/DocumentDetail/DocumentDetail'

const ExpiringDocuments: React.FC = () => {
  const userToken = GetAccessToken()
  const grid = useRef<any>(null)
  let exportedData: any

  const { userPermissions } = useContext(AuthContext)
  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const { profileClient } = useContext(AuthContext)
  const [selectedBusiness, setSelectedBusiness] = useState<any>()

  const [documentId, setDocumentId] = useState('')
  const [businessUploadRequestId, setbusinessUploadRequestId] = useState('')

  const [openDetailDialog, setOpenDetailDialog] = useState<boolean>(false)
  const [request, setRequest] = useState<any>(null)
  const { enqueueSnackbar } = useSnackbar()
  const notifyError = notistackOptions('error')
  const [total] = useState(0)
  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 10,
    filter: undefined,
    sort: undefined,
  })

  const closeDetailDialog = () => {
    setOpenDetailDialog(false)
    setDocumentId('')
    setbusinessUploadRequestId('')
  }

  const columns = [
    { field: 'originalFileName', title: 'File Name' },
    { field: 'documentName', title: 'Document Name' },
    { field: 'status', title: 'Status' },
    { field: 'uploadedByUserName', title: 'Uploaded By' },
    { field: 'uploadedOnUtc', title: 'Uploaded On' },
    { field: 'expirationDateUtc', title: 'Expiration Date' },
  ]

  const exportData = (dataToExport: any) => {
    if (dataToExport && dataToExport.length > 0) {
      exportedData.save(dataToExport, columns)
    } else {
      enqueueSnackbar('There is no data to be exported', notifyError)
    }
  }

  const [selectedDocument, setSelectedDocument] = useState<any>(null)

  const handleClick = (
    selectedDocumentId: string,
    selectedBusinessUploadedId: string,
    file: any
  ) => {
    const doc = { name: file.documentName, id: file.documentId }
    setbusinessUploadRequestId(selectedBusinessUploadedId)
    setSelectedDocument(doc)
    setDocumentId(selectedDocumentId)

    if (!getBusinessUploadRequest.isLoading) {
      setOpenDetailDialog(true)
    }
  }

  const handleBusiness = (business: any) => {
    setSelectedBusiness(business)
  }

  const getBusinesses = useQuery({
    queryKey: ['getBusinesses'],
    queryFn: async () => {
      return axios({
        url: process.env.REACT_APP_CONTROLPANEL,
        method: 'post',
        headers: {
          Authorization: userToken,
        },
        data: {
          query: `${getBusinessesQuery(isInternal ? null : profileClient.Id)}`,
        },
      }).then((response) => {
        return response.data
      })
    },
  })

  const getExpiringFiles = useQuery({
    queryKey: ['getExpiringFiles', gridState, selectedBusiness],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/businessupload/getExpiringFiles`,
        params: {
          businessId: selectedBusiness?.id,
          pagination: encodeURIComponent(JSON.stringify(gridState)),
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
  })

  const getBusinessUploadRequest = useQuery({
    queryKey: ['getBusinessUploadRequest', documentId],
    queryFn: async () => {
      if (documentId !== '') {
        axios({
          method: 'get',
          url: `${BACKEND_URL}/BusinessUploadRequest`,
          params: {
            documentId,
            businessId: selectedBusiness.id,
          },
          headers: {
            Authorization: userToken,
          },
        }).then((result: any) => {
          setRequest(result.data)
        })
      }
    },
    onSuccess: () => {
      setOpenDetailDialog(true)
    },
  })

  const businesses = getBusinesses?.data || []

  const expiringFiles = getExpiringFiles?.data || []

  return (
    <Box>
      <Box display="flex" justifyContent="center" flexDirection="row">
        <Grid container spacing={3} alignItems="center">
          <Grid
            item
            style={{
              width: '300px',
              alignItems: 'center',
              verticalAlign: 'center',
            }}
          >
            {getBusinesses.isLoading ? (
              <Skeleton />
            ) : (
              <TextField
                label="Business"
                select
                id="select-business"
                value={selectedBusiness?.name ?? ''}
                fullWidth
                style={{ minWidth: '250px' }}
              >
                <MenuItem value="" disabled={true}>
                  <em>Select business</em>
                </MenuItem>

                {businesses &&
                  businesses.data.getBusinesses.businesses.length > 0 &&
                  businesses.data.getBusinesses.businesses.map(
                    (business: any) => {
                      return (
                        <MenuItem
                          key={business.id}
                          value={business.name}
                          onClick={() => {
                            handleBusiness(business)
                          }}
                        >
                          {business.name}
                        </MenuItem>
                      )
                    }
                  )}
              </TextField>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box mt={6}>
        <Paper>
          {getExpiringFiles.isLoading ? (
            <Skeleton variant="rect" height={500} />
          ) : (
            <ExcelExport
              data={expiringFiles?.data}
              fileName={`ExpiringDocuments-${selectedBusiness?.name}.xlsx`}
              // eslint-disable-next-line no-return-assign
              ref={(exporter) => (exportedData = exporter)}
            >
              <Box>
                <Box display="flex" flexDirection="row" justifyContent="right">
                  <Button
                    title="Export Excel"
                    onClick={() => exportData(expiringFiles?.data)}
                    startIcon={<GetApp />}
                    disabled={!expiringFiles?.data}
                  >
                    Export to Excel
                  </Button>
                </Box>
                <Box pt={2}>
                  <KendoGrid
                    ref={grid}
                    style={{ height: '600px', cursor: 'pointer' }}
                    onRowClick={(e) => {
                      handleClick(
                        e.dataItem['documentId'],
                        e.dataItem['businessUploadRequestId'],
                        e.dataItem
                      )
                    }}
                    scrollable="scrollable"
                    data={expiringFiles || []}
                    skip={gridState.skip}
                    take={gridState.take}
                    pageSize={gridState.take}
                    filter={gridState.filter}
                    sort={gridState.sort}
                    sortable
                    pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
                    total={total}
                    onDataStateChange={(e) => {
                      setGridState(e.dataState)
                    }}
                  >
                    <KendoGridColumn
                      field="originalFileName"
                      title="File Name"
                      width="400px"
                      filter="text"
                      columnMenu={ColumnMenu}
                    />
                    <KendoGridColumn
                      field="documentName"
                      title="Document Name"
                      filter="text"
                      columnMenu={ColumnMenu}
                    />
                    <KendoGridColumn
                      field="status"
                      title="Status"
                      filter="text"
                      columnMenu={ColumnMenu}
                    />

                    <KendoGridColumn
                      field="uploadedByUserName"
                      title="Uploaded By"
                      filter="text"
                      columnMenu={ColumnMenu}
                    />
                    <KendoGridColumn
                      field="uploadedOnUtc"
                      title="Upload Date"
                      cell={(props: GridCellProps) => {
                        const field = props.field || ''
                        return (
                          <td>
                            {formatDateAndTimeLocal(props.dataItem[field])}
                          </td>
                        )
                      }}
                    />
                    <KendoGridColumn
                      field="expirationDateUtc"
                      title="Expiration Date"
                      filter="date"
                      columnMenu={ColumnMenu}
                      cell={(props: GridCellProps) => {
                        const field =
                          props.dataItem['expirationDateUtc'] || null
                        return (
                          <td>
                            {field === null
                              ? ''
                              : formatDateAndTimeLocal(field)}
                          </td>
                        )
                      }}
                    />
                  </KendoGrid>
                </Box>
              </Box>
            </ExcelExport>
          )}
        </Paper>
      </Box>
      <BusinessContext.Provider value={selectedBusiness}>
        {businessUploadRequestId &&
          documentId &&
          request &&
          openDetailDialog && (
            <DocumentDetail
              documentData={selectedDocument}
              open={openDetailDialog}
              close={closeDetailDialog}
              documentRequest={Number(businessUploadRequestId)}
              selectTab=""
              isRegistration={true}
              approval={false}
            />
          )}
      </BusinessContext.Provider>
    </Box>
  )
}

export default ExpiringDocuments
