import React, { useEffect, useState } from 'react'
import {
  Box,
  Card,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListSubheader,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Check, Close, ErrorOutline } from '@material-ui/icons'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { CONTROLPANEL_URL } from 'src/api/axios/api'
import { GetAccessToken } from 'src/api/axios/helper'
import MultipleSelectionDropDown from '../MultipleSelectionDropDown'
import { ConfirmDialog } from '../Dialogs'

interface IMediaSettings {
  handleChange: (name: any, value: any, setValue: boolean) => void
  inputDataForm: any
  selectItem: (id: string) => void
  fillableForms: any
  selectedItem: any
  readOnly: boolean
  deleteFormItem: (id: string) => void
}

const MediaSettings: React.FC<IMediaSettings> = ({
  handleChange,
  inputDataForm,
  selectItem,
  fillableForms,
  selectedItem,
  readOnly,
  deleteFormItem,
}: IMediaSettings) => {
  const userToken = GetAccessToken()
  const [assetTypes, setAssetTypes] = useState<any[]>([])
  const [assetTypesSelected, setAssetTypesSelected] = useState<any[]>([])
  const [namingConventionToRemove, setNamingConventionToRemove] = useState('')
  const [
    openDeleteNamingConventionDialog,
    setOpenDeleteNamingConventionDialog,
  ] = useState<boolean>(false)

  const { isLoading: loadingAssetType } = useQuery({
    queryKey: ['getAssetTypes'],
    queryFn: async () =>
      axios({
        method: 'get',
        url: `${CONTROLPANEL_URL}/client.getAssetTypes`,
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        const configuredAssetTypes = result.data
          .filter((e: any) =>
            (inputDataForm.values.assetType || []).some(
              (item: any) => item === e.id
            )
          )
          .map((x: any) => parseInt(x.id, 10))
        setAssetTypesSelected(configuredAssetTypes)
        setAssetTypes(result.data)
      }),
    cacheTime: 0,
  })

  const getAvailableAssetTypes = () => {
    const available = assetTypes
      ?.filter(
        (item) =>
          !inputDataForm.values.assetType?.some((x: any) => x.id === item.id)
      )
      .map((x) => {
        return {
          id: x.id,
          name: x.name,
          displayName: `${x.name}`,
        }
      })

    return available?.sort((a: any, b: any) =>
      a.displayName.localeCompare(b.displayName)
    )
  }

  const renderAssetType = () => {
    const handleChangeAssetType = (value: any) => {
      setAssetTypesSelected(value)
      handleChange('assetType', value, true)
    }

    const assetData = assetTypes ? getAvailableAssetTypes() : []
    return (
      <MultipleSelectionDropDown
        id="input-mediaSettings-assetType"
        label="Asset Types"
        data={assetData}
        disable={false}
        selectionState={assetTypesSelected}
        handleSelectionChange={handleChangeAssetType}
        displayProperties={['name']}
        hasValidationError={!!inputDataForm.errors.assetType}
        errorMessage={inputDataForm.errors.assetType}
      />
    )
  }

  const DeleteItemForm = (id: string) => {
    setOpenDeleteNamingConventionDialog(false)
    deleteFormItem(id)
  }

  useEffect(() => {
    setAssetTypesSelected(
      inputDataForm.values.assetType?.length === undefined
        ? []
        : inputDataForm.values.assetType
    )
  }, [inputDataForm])

  return (
    <>
      {loadingAssetType && (
        <Box>
          <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image" />
            <div className="k-loading-color" />
          </div>
        </Box>
      )}
      <Box display="flex" flexDirection="row" width="60em">
        <List
          style={{ minWidth: '250px' }}
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Naming Conventions Submitted
            </ListSubheader>
          }
        >
          {fillableForms &&
            fillableForms.map((x: any) => (
              <ListItem
                key={x.id}
                selected={
                  x.id ===
                  (selectedItem && selectedItem?.id !== null
                    ? selectedItem?.id
                    : null)
                }
              >
                <Box
                  borderColor="blue"
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ width: '100%' }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    style={{ width: '100%' }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      style={{ cursor: 'pointer' }}
                    >
                      <Tooltip
                        placement="right-end"
                        title={
                          x.status === 'Pending Upload'
                            ? 'Pending Submission (Rejected)'
                            : x.status
                        }
                      >
                        {x.status === 'Accepted' ? (
                          <Check fontSize="small" htmlColor="green" />
                        ) : x.status === 'Pending Upload' ? (
                          <Close fontSize="small" htmlColor="red" />
                        ) : (
                          <ErrorOutline fontSize="small" htmlColor="gold" />
                        )}
                      </Tooltip>
                    </Box>
                    <Box
                      style={{ cursor: 'pointer', width: '100%' }}
                      onClick={() => selectItem(x.id)}
                      ml={2}
                    >
                      {JSON.parse(x.formData)[0].nameIdentifier}
                    </Box>
                  </Box>
                  {x.status === 'Pending Acceptance' && (
                    <Box ml={2}>
                      <Tooltip title="Remove naming convention.">
                        <IconButton
                          style={{ padding: 0 }}
                          onClick={() => {
                            setNamingConventionToRemove(x.id)
                            setOpenDeleteNamingConventionDialog(true)
                          }}
                        >
                          <DeleteIcon color="primary" fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              </ListItem>
            ))}
        </List>
        <Box>
          <Card elevation={1} style={{ width: '100%' }}>
            <CardHeader title="Document Naming Convention" />
            <Grid
              container
              direction="row"
              style={{ margin: '6px 6px 6px 6px', width: '780px' }}
              spacing={3}
            >
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  disabled={readOnly}
                  data-cy="dialog-input-field-nameIdentifier"
                  label="Name Identifier"
                  name="nameIdentifier"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={({ target: { value, name } }) => {
                    handleChange(name, value, false)
                  }}
                  value={inputDataForm.values.nameIdentifier || ''}
                  error={!!inputDataForm.errors.nameIdentifier}
                  helperText={inputDataForm.errors.nameIdentifier}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {renderAssetType()}
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  disabled={readOnly}
                  data-cy="dialog-input-field-nameConvention"
                  label="Name Convention"
                  name="nameConvention"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    maxLength: 100,
                  }}
                  onChange={({ target: { value, name } }) => {
                    handleChange(name, value, false)
                  }}
                  value={inputDataForm.values.nameConvention || ''}
                  error={!!inputDataForm.errors.nameConvention}
                  helperText={inputDataForm.errors.nameConvention}
                />
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Box>
      {openDeleteNamingConventionDialog && (
        <ConfirmDialog
          open={openDeleteNamingConventionDialog}
          title="Remove Naming Convention"
          description={
            <>
              <Typography variant="body1" color="textSecondary">
                Are you sure you want to delete this naming convention?
              </Typography>
            </>
          }
          closeName="Cancel"
          confirmName="Continue"
          onClose={() => setOpenDeleteNamingConventionDialog(false)}
          onConfirm={() => DeleteItemForm(namingConventionToRemove)}
        />
      )}
    </>
  )
}

export default MediaSettings
