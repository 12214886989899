import React, { useContext } from 'react'
import { Card, CardHeader, Grid, TextField } from '@material-ui/core'
import { maskPhoneNumber } from 'src/utils/masker'
import { AuthContext } from 'src/context/AuthenticationContext'

interface IReferences {
  handleChange: (name: any, value: any) => void
  inputDataForm: any
  readOnly: boolean
}

const References: React.FC<IReferences> = ({
  handleChange,
  inputDataForm,
  readOnly,
}: IReferences) => {
  const { profileClient } = useContext(AuthContext)
  const country = profileClient?.Country || process.env.REACT_APP_COUNTRY

  return (
    <>
      <Card elevation={1} style={{ width: '100%' }}>
        <CardHeader
          title="References"
          subheader="At least 2 references the company has had a business relationship
          with within the last 2 years."
        />
        <Grid
          container
          direction="row"
          style={{ margin: '6px 6px 6px 6px', width: '800px' }}
          spacing={3}
        >
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              data-cy="dialog-input-field-companyname"
              label="Company name "
              name="companyName"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
              value={inputDataForm.values.companyName}
              error={!!inputDataForm.errors.companyName}
              helperText={inputDataForm.errors.companyName}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              data-cy="dialog-input-field-contactName"
              label="Contact name"
              name="contactName"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
              value={inputDataForm.values.contactName}
              error={!!inputDataForm.errors.contactName}
              helperText={inputDataForm.errors.contactName}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-contactPhone"
              label="Contact telephone"
              name="contactPhone"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, country)
                handleChange(name, masked)
              }}
              value={inputDataForm.values.contactPhone}
              error={!!inputDataForm.errors.contactPhone}
              helperText={inputDataForm.errors.contactPhone}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-companyname2"
              label="Company name"
              name="companyname2"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
              value={inputDataForm.values.companyname2}
              error={!!inputDataForm.errors.companyname2}
              helperText={inputDataForm.errors.companyname2}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-contactName2"
              label="Contact name"
              name="contactName2"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
              value={inputDataForm.values.contactName2}
              error={!!inputDataForm.errors.contactName2}
              helperText={inputDataForm.errors.contactName2}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-contactPhone2"
              label="Contact telephone"
              name="contactPhone2"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, country)
                handleChange(name, masked)
              }}
              value={inputDataForm.values.contactPhone2}
              error={!!inputDataForm.errors.contactPhone2}
              helperText={inputDataForm.errors.contactPhone2}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-companyname3"
              label="Company name"
              name="companyname3"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
              value={inputDataForm.values.companyname3}
              error={!!inputDataForm.errors.companyname3}
              helperText={inputDataForm.errors.companyname3}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-contactName3"
              label="Contact name"
              name="contactName3"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
              value={inputDataForm.values.contactName3}
              error={!!inputDataForm.errors.contactName3}
              helperText={inputDataForm.errors.contactName3}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              disabled={readOnly}
              fullWidth
              data-cy="dialog-input-field-contactPhone3"
              label="Contact telephone"
              name="contactPhone3"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 100,
              }}
              onChange={({ target: { value, name } }) => {
                const masked = maskPhoneNumber(value, country)
                handleChange(name, masked)
              }}
              value={inputDataForm.values.contactPhone3}
              error={!!inputDataForm.errors.contactPhone3}
              helperText={inputDataForm.errors.contactPhone3}
            />
          </Grid>
        </Grid>
      </Card>

      <Card elevation={1} style={{ marginTop: '6px', width: '100%' }}>
        <CardHeader
          title="Associations affiliations"
          subheader="List all associations where your company is a current and active
          member."
        />
        <Grid
          container
          direction="row"
          style={{
            marginTop: '6px',
            marginLeft: '6px',
            marginBottom: '6px',
            width: '800px',
          }}
          spacing={3}
        >
          <Grid item xs={12} md={12}>
            <TextField
              disabled={readOnly}
              variant="outlined"
              fullWidth
              multiline={true}
              rows={4}
              data-cy="dialog-input-field-associations"
              name="associations"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                maxLength: 200,
              }}
              onChange={({ target: { value, name } }) => {
                handleChange(name, value)
              }}
              value={inputDataForm.values.associations}
              error={!!inputDataForm.errors.associations}
              helperText={inputDataForm.errors.associations}
            />
          </Grid>
        </Grid>
      </Card>
    </>
  )
}

export default References
