import { Box } from '@material-ui/core'
// import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popover from '@material-ui/core/Popover'
import Popper from '@material-ui/core/Popper'
import Toolbar from '@material-ui/core/Toolbar'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import MenuIcon from '@material-ui/icons/Menu'
// import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined'
import React, { useEffect, useRef, useState, memo } from 'react'
import { useAuth } from 'src/context/AuthenticationContext'

import Menus from './Menus'
import {
  AppBarStyle,
  DrawerStyle,
  ListNotify,
  Logo,
  Main,
  Root,
  SectionDesktop,
  ToolbarStyles,
  ContentBox,
} from './styles'
import Footer from './Footer'

interface Props {
  badgeNotify?: number
  children: React.ReactNode
}

const userId = window.localStorage.getItem('userId')
const { origin } = window.location
const changePasswordlink = `${process.env.REACT_APP_AUTHORITY_URL}/account/ChangePassword?userid=${userId}&returnurl=${origin}`

const MainLayout: React.FC<Props> = ({ badgeNotify, children }: Props) => {
  const authenticationObj = useAuth()
  // const mediaMatches = useMediaQuery('(min-width: 738px)')
  // const mediaMatchesMin1138 = useMediaQuery('(max-width: 1138px)')
  const [openDrawer, setOpenDrawer] = useState(false)
  const [openUser, setOpenUser] = useState(false)
  const anchorRefUser = useRef<HTMLButtonElement>(null)
  const [anchorElNotify, setAnchorElNotify] =
    React.useState<HTMLButtonElement | null>(null)

  const handleDrawerOpen = (): void => {
    setOpenDrawer((prevOpen) => !prevOpen)
  }

  const handleUserMenuToggle = (): void => {
    setOpenUser((prevOpen) => !prevOpen)
  }

  const handleUserClose = (event: React.MouseEvent<EventTarget>): void => {
    if (
      anchorRefUser.current &&
      anchorRefUser.current.contains(event.target as HTMLElement)
    ) {
      return
    }
    setOpenUser(false)
  }

  const handleChangepassword = (): void => {
    window.open(changePasswordlink, '_blank')
    setOpenUser(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent): void {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpenUser(false)
    }
  }

  const handleNotifyClose = (): void => {
    setAnchorElNotify(null)
  }

  const handleOnLogout = (): void => {
    authenticationObj.logout()
  }

  const openNotify = Boolean(anchorElNotify)
  const notifyID = openNotify ? 'notify-popover' : undefined

  const prevOpen = React.useRef(openUser)

  const [expanded, setExpanded] = React.useState<string>('')

  const handleMenuChange = (panel: string, isExpanded: boolean) => {
    setExpanded(isExpanded ? '' : panel)
  }

  useEffect(() => {
    if (prevOpen.current === true && openUser === false) {
      anchorRefUser.current!.focus()
    }

    prevOpen.current = openUser
  }, [openUser])

  return (
    <Root>
      <AppBarStyle position="fixed" elevation={0}>
        <ToolbarStyles>
          <Box color="#fff">
            <IconButton
              role="menu"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerOpen}
              style={{ position: 'relative', zIndex: 100 }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box
            alignItems="center"
            justifyContent="space-between"
            display="flex"
            flexDirection="row"
            width="100%"
            paddingLeft={3}
          >
            <Logo
              data-testid="debttrader-logo"
              src="/img/ec-white-logo.svg"
              width={230}
              alt="everchain"
            />
            <Box
              width="100%"
              maxWidth={750}
              display="flex"
              flexDirection="row"
              alignItems="center"
            />
            <SectionDesktop>
              {/* <IconButton
              aria-describedby={notifyID}
              aria-label="show 17 new notifications"
              onClick={handleNotifyClick}
              color="inherit"
            >
              <Badge badgeContent={badgeNotify} color="primary">
                <NotificationsOutlinedIcon style={{ fill: 'white' }} />
              </Badge>
            </IconButton> */}
              <>
                <Button
                  ref={anchorRefUser}
                  style={{ color: 'white' }}
                  aria-controls={openUser ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  data-cy="user-menu"
                  onClick={handleUserMenuToggle}
                  startIcon={<AccountCircleIcon />}
                >
                  {
                    authenticationObj.user?.profile[
                      'http://debttrader.com/claims/display_name'
                    ]
                  }
                </Button>
                <Popper
                  open={openUser}
                  anchorEl={anchorRefUser.current}
                  role={undefined}
                  transition
                  data-cy="popper-menu"
                  disablePortal
                >
                  {({ TransitionProps, placement }): React.ReactNode => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleUserClose}>
                          <MenuList
                            autoFocusItem={openUser}
                            id="menu-list-grow"
                            // eslint-disable-next-line react/jsx-no-bind
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              onClick={handleChangepassword}
                              role="menu-item"
                            >
                              Change Password
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
              <Popover
                id={notifyID}
                open={openNotify}
                anchorEl={anchorElNotify}
                onClose={handleNotifyClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <ListNotify>
                  <List>
                    <ListSubheader>Notify EverChain</ListSubheader>
                    <ListItem>
                      <ListItemText primary="You're have a notify" />
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="You're have another notify" />
                    </ListItem>
                  </List>
                </ListNotify>
              </Popover>
              <Button
                data-cy="logout-button"
                variant="text"
                style={{ color: 'white' }}
                onClick={handleOnLogout}
              >
                Logout
              </Button>
            </SectionDesktop>
            {/* <SectionDesktop flexgrow={0} borderleft>
            <Button
              variant="text"
              style={{ color: 'white' }}
              onClick={handleOnLogout}
            >
              Logout
            </Button>
          </SectionDesktop> */}
          </Box>
        </ToolbarStyles>
      </AppBarStyle>
      <Box display="flex" flexDirection="row" height="inherit">
        <DrawerStyle
          variant={openDrawer ? 'temporary' : 'permanent'}
          open={openDrawer}
          onClose={() => {
            setOpenDrawer(false)
          }}
          elevation={0}
          style={{ position: 'relative' }}
        >
          <Toolbar className="toolbar-custom">
            <Box display="flex" flex="1" color="#fff">
              <IconButton
                role="menu"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box display="flex" flex="2" className="logo-sidebar">
              <Logo
                data-testid="debttrader-logo"
                src="/img/ec-white-logo.svg"
                width={200}
                alt="everchain"
              />
            </Box>
          </Toolbar>
          <Menus
            opendedMenu={openDrawer}
            onCloseDrawer={() => {
              setOpenDrawer(false)
            }}
            menuExpanded={expanded}
            handleMenuChange={
              (item: string, isExpanded: boolean) =>
                handleMenuChange(item, isExpanded)
              // eslint-disable-next-line react/jsx-curly-newline
            }
          />
        </DrawerStyle>
        <ContentBox>
          <Main>{children}</Main>
          <Footer />
        </ContentBox>
      </Box>
    </Root>
  )
}

MainLayout.defaultProps = {
  badgeNotify: 2,
}

export default memo(MainLayout)
