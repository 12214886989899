import {
  Avatar,
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { GetAccessToken } from 'src/api/axios/api'
import axios from 'axios'
import Skeleton from '@material-ui/lab/Skeleton'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'

import {
  GetCertificationComments,
  SaveCertificationComments,
} from 'src/api/operations/get/business/urls'
import { getDateLabel, stringAvatar } from 'src/utils/common'
import { CertificationPeriodContext } from 'src/context/CertificationPeriodContext'
import Loader from '../Loader'

interface ICertificationComment {
  businessId: string
  close: () => void
}

const CertificationComment: React.FC<ICertificationComment> = ({
  businessId,
  close,
}: ICertificationComment) => {
  const { enqueueSnackbar } = useSnackbar()
  const notistackSucces = notistackOptions('success')
  const notistackError = notistackOptions('error')
  const queryClient = useQueryClient()

  const [commentToAdd, setCommentToAdd] = useState<string>('')
  const certificationPeriodSelected = useContext(CertificationPeriodContext)

  const userToken = GetAccessToken()

  const queryCommentsData = useQuery({
    queryKey: ['getComments', businessId],
    queryFn: async () =>
      axios({
        method: 'get',
        url: GetCertificationComments,
        headers: {
          Authorization: userToken,
        },
        params: {
          businessId,
          pagination: null,
        },
      }).then((result: any) => {
        return result.data
      }),
    refetchInterval: 50000,
  })
  const commentsData = queryCommentsData.data

  const mutationAddComment = useMutation({
    mutationFn: async () =>
      axios({
        method: 'post',
        url: SaveCertificationComments,
        data: {
          businessId,
          comment: commentToAdd,
        },
        headers: {
          Authorization: userToken,
        },
      }),
    onSuccess: async () => {
      enqueueSnackbar('New comment added.', notistackSucces)
      await queryClient.refetchQueries({
        queryKey: 'getComments',
      })
      setCommentToAdd('')
    },
    onError: async (error: any) => {
      enqueueSnackbar(error.response.data, notistackError)
    },
  })

  const handleAddComment = () => {
    mutationAddComment.mutate()
  }

  return (
    <Box display="flex" flexDirection="column">
      <Box height={400} width={450}>
        {mutationAddComment.isLoading && <Loader />}
        <List style={{ maxHeight: '100%', overflow: 'auto' }}>
          {queryCommentsData.isLoading && (
            <>
              <Skeleton height={80} width="95%" />
              <Skeleton height={80} width="95%" />
              <Skeleton height={80} width="95%" />
              <Skeleton height={80} width="95%" />
            </>
          )}

          {!queryCommentsData.isLoading &&
            !commentsData?.businessAuditComments.length && (
              <Box display="flex" flexDirection="column">
                <Typography>There are no comments</Typography>
              </Box>
            )}

          {commentsData?.businessAuditComments?.map((item: any) => {
            return (
              <div key={`div_${item.id}`}>
                <ListItem alignItems="flex-start" key={`item_${item.id}`}>
                  <ListItemAvatar key={`avatar_${item.id}`}>
                    <Avatar {...stringAvatar(item.userName)} />
                  </ListItemAvatar>
                  <ListItemText
                    key={`text_${item.id}`}
                    primary={`${item.userName} - ${getDateLabel(
                      item.creationDateUtc
                    )} - Certification: ${item.certificationDate}`}
                    secondary={item.comment}
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </div>
            )
          })}
        </List>
      </Box>
      {certificationPeriodSelected.id === null && (
        <Box display="flex" flexDirection="column" mt={3}>
          <Box>
            <TextField
              label="Comment"
              variant="outlined"
              multiline
              minRows={2}
              maxRows={2}
              fullWidth
              inputProps={{
                maxLength: 250,
              }}
              disabled={mutationAddComment.isLoading}
              value={commentToAdd}
              onChange={(event) => {
                setCommentToAdd(event.currentTarget.value)
              }}
            />
          </Box>

          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>Number of Comments: {commentsData?.total} </Box>
            <Box display="flex" flexDirection="row" mt={2}>
              <Button
                style={{ marginRight: 5 }}
                variant="outlined"
                color="secondary"
                onClick={() => close()}
                disabled={mutationAddComment.isLoading}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleAddComment}
                disabled={mutationAddComment.isLoading || !commentToAdd}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default CertificationComment
