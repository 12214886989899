import {
  Button,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { BACKEND_URL } from 'src/api/axios/api'
import { GetAccessToken } from 'src/api/axios/helper'
import { notistackOptions } from 'src/configs/notistackOptions'
import CategoryOrderBox from './CategoryOrderBox'

interface CategoryOrderProps {
  close: () => void
}

const CategoryOrder: React.FC<CategoryOrderProps> = ({
  close,
}: CategoryOrderProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const notistackSucces = notistackOptions('success')
  const notistackError = notistackOptions('error')
  const userToken = GetAccessToken()
  const [businessTypeName, SetBusinessTypeName] =
    useState<string>('Buyer Active')
  const [categoryOrderData, SetCategoryOrderData] = useState<any[]>([])
  const [dragId, setDragId] = useState<number>()
  const [cursor, setCursor] = useState('grab')
  const [isLoading, setIsloading] = useState<boolean>(false)

  useEffect(() => {
    setIsloading(true)
    axios({
      method: 'get',
      url: `${BACKEND_URL}/categoryorder`,
      params: { businessTypeName },
      headers: {
        Authorization: userToken,
      },
    })
      .then((response) => {
        SetCategoryOrderData(response.data)
        setIsloading(false)
      })
      .catch(() => {
        enqueueSnackbar('Error loading data', notistackError)
        setIsloading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessTypeName, userToken])

  const handleDrag = (ev: any) => {
    // eslint-disable-next-line radix
    setDragId(parseInt(ev.currentTarget.id, 0))
    setCursor('grabbing')
  }

  const handleDrop = (ev: any) => {
    // eslint-disable-next-line radix
    const id = parseInt(ev.currentTarget.id, 0)
    const dragBox = categoryOrderData.find((c) => c.categoryId === dragId)
    const dropBox = categoryOrderData.find((c) => c.categoryId === id)

    if (dragBox && dropBox) {
      const dragBoxOrder = dragBox.orderId
      const dropBoxOrder = dropBox.orderId

      const newBoxState = categoryOrderData.map((categoryOrder) => {
        if (categoryOrder.categoryId === dragId) {
          // eslint-disable-next-line no-param-reassign
          categoryOrder.orderId = dropBoxOrder
        }
        if (categoryOrder.categoryId === id) {
          // eslint-disable-next-line no-param-reassign
          categoryOrder.orderId = dragBoxOrder
        }
        return categoryOrder
      })

      SetCategoryOrderData(newBoxState)
    }
    setCursor('grab')
  }

  const handleChange = (event: any) => {
    SetBusinessTypeName(event.target.value)
  }

  const handleClose = () => {
    close()
  }

  const handleSave = () => {
    setIsloading(true)
    axios({
      method: 'post',
      url: `${BACKEND_URL}/categoryorder`,
      data: {
        categoryOrderRequests: categoryOrderData,
      },
      headers: {
        Authorization: userToken,
      },
    })
      .then((response) => {
        setIsloading(false)
        SetCategoryOrderData(response.data)
        enqueueSnackbar('Category order saved', notistackSucces)
        handleClose()
      })
      .catch(() => {
        enqueueSnackbar('Error saving data', notistackError)
      })
  }

  return (
    <div>
      <FormGroup>
        <FormControl>
          <InputLabel
            data-cy="dialog-input-businesstypename"
            id="select-businesstypename"
          >
            Business Type
          </InputLabel>
          <Select
            labelId="select-businesstypename"
            name="businessTypeNameSelection"
            style={{ width: '350px' }}
            fullWidth
            value={businessTypeName}
            onChange={handleChange}
          >
            <MenuItem value="Buyer Active">Buyer Active</MenuItem>
            <MenuItem value="Buyer Passive">Buyer Passive</MenuItem>
            <MenuItem value="Seller">Seller</MenuItem>
            <MenuItem value="Agency">Agency</MenuItem>
          </Select>
        </FormControl>
      </FormGroup>
      {isLoading ? (
        <>
          <Skeleton variant="rect" height={50} style={{ marginTop: '8px' }} />
          <Skeleton variant="rect" height={50} style={{ marginTop: '8px' }} />
          <Skeleton variant="rect" height={50} style={{ marginTop: '8px' }} />
          <Skeleton variant="rect" height={50} style={{ marginTop: '8px' }} />
          <Skeleton variant="rect" height={50} style={{ marginTop: '8px' }} />
          <Skeleton variant="rect" height={50} style={{ marginTop: '8px' }} />
          <Skeleton variant="rect" height={50} style={{ marginTop: '8px' }} />
          <Skeleton
            variant="rect"
            height={50}
            style={{ marginTop: '8px', marginBottom: '8px' }}
          />
        </>
      ) : (
        <>
          {categoryOrderData
            .sort((a, b) => a.orderId - b.orderId)
            .map((categoryOrder) => (
              <CategoryOrderBox
                key={categoryOrder.categoryId}
                boxId={categoryOrder.categoryId}
                boxName={categoryOrder.categoryName}
                handleDrag={handleDrag}
                handleDrop={handleDrop}
                cursor={cursor}
              />
            ))}
        </>
      )}

      <div
        style={{
          marginTop: 5,
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '6px',
        }}
      >
        <div>
          <Button
            disabled={isLoading}
            variant="outlined"
            color="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
        <div>
          <Button
            disabled={isLoading}
            variant="contained"
            color="primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CategoryOrder
