import { Box, Grid, Paper, Typography } from '@material-ui/core'
import React from 'react'
import UploadSOP from 'src/components/UploadSOP'

const LoadSOP: React.FC = () => {
  const handleClose = () => {}
  return (
    <>
      <Box padding={4} mt={4}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle1">Load SOP</Typography>
          </Grid>
          <Paper style={{ padding: 5, width: '100%' }}>
            <Grid item xs={12} md={12} lg={12}>
              <UploadSOP close={handleClose} />
            </Grid>
          </Paper>
        </Grid>
      </Box>
    </>
  )
}

export default LoadSOP
