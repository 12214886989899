import { Box, Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import Loader from 'src/components/Loader'
import { GridColumn as KendoGridColumn } from '@progress/kendo-react-grid'
import { StyledKendoGrid as KendoGrid } from 'src/styles/kendoGridStyle'
import SectionCard from 'src/components/SectionCard'
import { ColumnMenu } from 'src/components/Filters/HeaderFilter'
import { State } from '@progress/kendo-data-query'
import { getColumns } from './CertificationManagerColumns'

const CertificationManager: React.FC = () => {
  const [option, setOption] = useState('')
  const [cardType, setCardType] = useState('')
  const userToken = GetAccessToken()
  const grid = useRef<any>(null)
  const [columns, setColumns] = useState<any[]>([])

  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const getCertificationData = useQuery({
    enabled: option !== '',
    queryKey: ['getCertificationManagerCards', option],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/certificationmanager/getCertificationManagerCards/${option}`,
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
  })

  const certificationData = getCertificationData?.data || []

  const getCertificationGridData = useQuery({
    enabled: option !== '' && cardType !== '',
    queryKey: ['getCertificationManagerGrid', option, cardType, gridState],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/certificationmanager/getCertificationManagerGrid`,
        params: {
          type: option,
          cardType,
          pagination: encodeURIComponent(JSON.stringify(gridState)),
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
  })

  const certificationGridData = getCertificationGridData?.data || []

  const handleCardType = (card: string) => {
    setCardType(card)
  }

  const loading = getCertificationGridData.isLoading

  useEffect(() => {
    if (option !== '') {
      switch (option) {
        case 'Certification':
        case 'Re-Certification':
          setCardType('currently-in-certification')
          break
        case 'Analyst':
          setCardType('')
          break
      }
    }
  }, [option])

  useEffect(() => {
    if (cardType !== '') setColumns(getColumns(option, cardType))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardType])

  return (
    <>
      <Box padding={4} mt={4}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle1">Certification Manager</Typography>
          </Grid>
        </Grid>
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={2} md={3}>
              <TextField
                data-cy="select-option"
                id="select-option"
                fullWidth
                select
                label="Option"
                name="result"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setOption(e.target.value)}
                value={option || null}
              >
                <MenuItem value="">
                  <em>Select an option</em>
                </MenuItem>

                {/* <MenuItem value="Analyst" key="Analyst">
                  Analyst
                </MenuItem> */}
                <MenuItem value="Certification" key="Certification">
                  Certification
                </MenuItem>
                <MenuItem value="Re-Certification" key="Re-Certification">
                  Re-Certification
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Box>

        <Box mt={10}>
          {getCertificationData.isLoading && <Loader />}
          <Grid container>
            {option !== '' &&
              cardType !== '' &&
              !getCertificationData.isLoading && (
                <Grid item xs={12} md={12}>
                  <SectionCard
                    cards={certificationData || []}
                    onCardClick={handleCardType}
                    selected={cardType}
                  />
                </Grid>
              )}
          </Grid>
        </Box>
        <Box mt={10}>
          {loading && <Loader />}
          {cardType !== '' && (
            <Box>
              <KendoGrid
                ref={grid}
                scrollable="scrollable"
                data={certificationGridData?.items ?? []}
                skip={gridState.skip}
                take={gridState.take}
                pageSize={gridState.take}
                filter={gridState.filter}
                sort={gridState.sort}
                sortable
                pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
                total={certificationGridData?.total ?? 0}
                onDataStateChange={(e) => {
                  setGridState(e.dataState)
                }}
                style={{ minHeight: '200px', maxHeight: '500px' }}
              >
                {columns.reduce((acc, item, idx) => {
                  const component = [...acc]

                  item.show &&
                    component.push(
                      <KendoGridColumn
                        key={item.field + item.title}
                        field={item.field}
                        title={item.title}
                        cell={item.cell}
                        width={item.width}
                        columnMenu={ColumnMenu}
                      />
                    )

                  return component
                }, [])}
              </KendoGrid>
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default CertificationManager
