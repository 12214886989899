import { Grid, Box, Typography } from '@material-ui/core'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { BACKEND_URL } from 'src/api/axios/api'
import { GetAccessToken } from 'src/api/axios/helper'
import { BusinessContext } from 'src/context/BusinessContext'
import hardcodeData from 'src/utils/hardcodeData'
import { isUkCountry } from 'src/utils/common'
import { AuthContext } from 'src/context/AuthenticationContext'
import { SurveyGetSurvey, SurveyType } from 'src/api/operations/get/survey/urls'
import LicenseAttestationCard from '../LicenseAttestationCard/LicensingAttestationCard'
import DocumentCard from '../DocumentCard/DocumentCard'
import { FillableDocumentCard } from '../FillableDocumentCard'

interface DocumentCardMainProps {
  categoryData: any
  businessUsers: any
  filteredUsers: any[]
  origin: string
  recertification: boolean
  disableItems: boolean
}

const DocumentCardMain: React.FC<DocumentCardMainProps> = ({
  categoryData,
  businessUsers,
  filteredUsers,
  origin,
  recertification,
  disableItems,
}: DocumentCardMainProps) => {
  const userToken = GetAccessToken()
  const businessContext = useContext<any>(BusinessContext)
  const { profileClient } = useContext(AuthContext)

  const [documents, setDocuments] = useState([])

  const [fillableForms, setFillableForms] = useState([])

  const [surveys, setSurveys] = useState([])

  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )

  const documentsRequiredQuery = useQuery({
    queryKey: [
      'documentsRequired',
      categoryData.id,
      businessContext.id,
      filteredUsers,
    ],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/documents`,
        params: {
          categoryId: categoryData.id,
          isEnabled: true,
          businessType: businessContext.type,
          businessId: businessContext.id,
          filteredUser: filteredUsers.toString(),
          assetTypes: businessContext.assetTypes
            ? JSON.stringify(businessContext.assetTypes)
            : null,
          servicerType: businessContext?.servicerType,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setDocuments(result.data.documents)
      })
    },
    cacheTime: 0,
  })

  const fillableDocsQuery = useQuery({
    queryKey: ['fillableDocsRequired', categoryData.id, businessContext.id],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/fillableForm`,
        params: {
          categoryId: categoryData.id,
          isEnabled: true,
          businessType: businessContext.type,
          businessId: businessContext.id,
          servicerType: businessContext?.servicerType,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setFillableForms(result.data.fillableForms)
      })
    },
    cacheTime: 0,
  })

  const getSellerSurvey = useQuery({
    queryKey: ['getSurvey', businessContext.id],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: SurveyGetSurvey,
        params: {
          surveyType: SurveyType.DigitalSellerSurvey,
          businessId: businessContext.id,
          kendoPagination: undefined,
          showOnlyArchived: false,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setSurveys(result.data.surveyResponses)
      })
    },
    cacheTime: 0,
    enabled: fillableForms?.length > 0,
  })

  const showExecutive = (cardType: string) => {
    return cardType === 'executives' && !isUk
  }

  const showCompanyRegistration = (cardType: string) => {
    return cardType === 'company-registrations-trading-names' && isUk
  }

  const showCard = (cardType: string) => {
    const show = !!(
      showCompanyRegistration(cardType) ||
      showExecutive(cardType) ||
      !['company-registrations-trading-names', 'executives'].includes(cardType)
    )
    return show
  }

  return (
    <>
      {!documentsRequiredQuery.isLoading &&
        !fillableDocsQuery.isLoading &&
        !getSellerSurvey.isLoading && (
          <Grid
            container
            spacing={5}
            style={{
              display: 'flex',
            }}
          >
            {categoryData.containsLicenseAttestation &&
              origin === 'registration' && <LicenseAttestationCard />}

            {documents?.length > 0 &&
              documents?.map(
                (x: any) =>
                  ((x.allowAudioFile &&
                    ['Agency', 'Buyer'].includes(businessContext.type)) ||
                    !x.allowAudioFile) && (
                    <Grid
                      key={`GridItem_${x.id}`}
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      xl={4}
                      lg={4}
                    >
                      <DocumentCard
                        key={`DocumentCard_${x.id}`}
                        documentData={x}
                        businessUsers={businessUsers}
                        origin={origin}
                        disableItems={disableItems}
                      />
                    </Grid>
                  )
              )}

            {fillableForms?.length > 0 &&
              fillableForms?.map(
                (file: any) =>
                  showCard(file.type) &&
                  (file.type === 'digital-survey' ? (
                    surveys?.map((f: any) => (
                      <>
                        <FillableDocumentCard
                          fillableItem={file}
                          key={file.id}
                          origin={origin}
                          disableItems={disableItems}
                          surveyId={f.id}
                          surveyName={f.name}
                        />
                      </>
                    ))
                  ) : (
                    <FillableDocumentCard
                      fillableItem={file}
                      key={file.id}
                      origin={origin}
                      disableItems={disableItems}
                      surveyName=""
                    />
                  ))
              )}
            {documents.length === 0 &&
              fillableForms.length === 0 &&
              !categoryData.containsLicenseAttestation && (
                <Box display="flex" flexDirection="row" justifyContent="center">
                  <Typography>No available documents</Typography>
                </Box>
              )}
          </Grid>
        )}
    </>
  )
}

export default DocumentCardMain
