import { Grid as KendoGrid } from '@progress/kendo-react-grid'
import styled, { css } from 'styled-components'

import { Colors } from './themes/colors'
import { defaultValueFontSize, defaultFontFamily } from './themes/default'

interface KendoGridStyleProps {
  twoLinesHeader?: boolean
  primarycolor?: string
  secondarycolor?: string
  backgroundcolor?: string
  ispageable?: boolean
}

const borderRadius = '10px'
const fontSize = defaultValueFontSize
const border = `1px solid ${Colors.gray}`

export const StatusSpan = styled.span<{ enabled: boolean }>`
  display: inline-flex;
  align-items: center;
  padding: 0px 8px 0px 3px;
  border-radius: 50px;
  color: ${(props) => (props.enabled ? Colors.success : Colors.error)};
  background-color: ${Colors.white};
  border: 1px solid
    ${(props) => (props.enabled ? Colors.success : Colors.error)};
`

const twoLinesHeaderProperties = css`
  .k-column-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    -webkit-box-align: center;
  }

  .k-link {
    height: 45px;
  }
`

const bottomRondedUnpagenable = css`
  .k-grid-content {
    border-radius: 0;
    border-bottom-left-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
  }
`

export const StyledKendoGrid = styled(KendoGrid)<KendoGridStyleProps>`
  ${({ twoLinesHeader }) => twoLinesHeader && twoLinesHeaderProperties}
  ${({ ispageable }) => !ispageable && bottomRondedUnpagenable}

  border-radius: ${borderRadius};

  .k-grid-header {
    font-size: ${fontSize};
    font-family: ${defaultFontFamily};
    color: ${(props) => props.backgroundcolor};
    background-color: ${(props) => props.primarycolor};
    border-top-left-radius: ${borderRadius};
    border-top-right-radius: ${borderRadius};
    border-bottom: ${border};
    padding-inline-end: var(--kendo-scrollbar-width, 17px);
  }

  .k-table-thead {
    background-color: transparent;
    color: ${(props) => props.backgroundcolor};
    font-size: ${fontSize};
  }

  .k-table-row {
    font-size: ${fontSize};
  }

  .k-grid-header:hover,
  .k-link:hover,
  .k-grid-header .k-sorted,
  .k-grid-header-menu,
  .k-grid-header-menu:hover,
  .k-grid-header .k-sort-icon {
    color: ${(props) => props.backgroundcolor};
  }

  .k-grid-header-menu {
    margin: 0 !important;
  }

  .k-grid-header-menu:focus {
    color: ${(props) => props.backgroundcolor} !important;
  }

  .k-grid-header th,
  .k-grid-content td {
    border-left: ${border};
  }

  .k-grid-header th {
    padding: 7px 25px 7px 10px;
    vertical-align: middle !important;
  }

  .k-table td {
    padding: 7px;
  }

  .k-cell-inner {
    font-weight: 500;
    letter-spacing: 0.02em;
  }

  .dropdown-country-wrap {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    white-space: nowrap;
  }

  .k-grid-header-wrap {
    border-radius: ${borderRadius} !important;
  }

  .k-grid-header-wrap > table {
    width: 100% !important;
  }

  .k-grid-content table {
    width: 100% !important;
  }

  tr:hover td {
    background-color: ${(props) => props.secondarycolor};
  }

  .k-pager {
    background-color: transparent;
    padding: 10px;
    box-sizing: border-box;
    outline: 0;
    white-space: nowrap;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: default;
    flex: 0 0 auto;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .k-pager-numbers .k-button.k-selected {
    color: ${(props) => props.backgroundcolor};
    background-color: ${Colors.blue} !important;
  }

  .k-pager-numbers .k-button:not(.k-selected):hover {
    background-color: ${(props) => props.secondarycolor} !important;
  }

  .k-button-flat-primary {
    color: ${Colors.blue};
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: ${fontSize};
    font-family: ${defaultFontFamily};
  }

  .k-dropdownlist {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${Colors.darkGray};
    margin-right: 5px;
    color: ${Colors.black};
    background-color: transparent;
    font-size: ${fontSize};
    width: 90px !important;
  }

  .k-pager-sizes,
  .k-pager-info {
    color: ${Colors.darkGray};
    font-size: ${fontSize};
    font-family: ${defaultFontFamily};
  }

  .k-input-inner {
    width: 70px;
  }

  .k-grid .k-grid-aria-root {
    border-color: inherit;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
    border-radius: inherit;
  }

  .k-input-button {
    width: 25px;
  }
`
export const TdNoEllipsis = styled.td`
  text-overflow: unset !important;
`
