import { Typography } from '@material-ui/core'
import styled from 'styled-components'

interface CardStyleProps {
  selected?: boolean
  cardtype?: string
  alignCard: 'center' | 'left' | 'right'
  hover?: boolean
  elevation: number
  square?: boolean
  colorFull?: boolean
  infoCursor?: boolean
  variant: 'elevation' | 'outlined'
}

export const CardHeader = styled.div`
  padding: 8px;
`

export const CardNotify = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 1;
`

export const CardFooter = styled.div`
  padding: 8px;
`

export const Title = styled(Typography)`
  font-size: 1rem;
`
export const TextContent = styled(Typography)`
  font-size: 2.4rem;
  font-weight: 400;
`

export const TextContentGreen = styled(Typography)`
  font-size: 2.4rem;
  font-weight: 400;
  color: green;
`

export const TextContentYellow = styled(Typography)`
  font-size: 2.4rem;
  font-weight: 400;
  color: #fafa46;
`

export const TextContentRed = styled(Typography)`
  font-size: 2.4rem;
  font-weight: 400;
  color: red;
`

export const TextFooter = styled(Typography)`
  font-size: 1rem;
  font-weight: 500;
`

export const Card = styled.div<CardStyleProps>`
  position: relative;
  cursor: ${({ infoCursor }): string => (infoCursor ? 'pointer' : 'auto')};
  border-radius: ${({ square }): string => (square ? '0' : '15px')};
  height: 100;
  background-color: ${({ theme, colorFull, selected }): string =>
    !colorFull
      ? !selected
        ? theme.palette.common.white
        : theme.palette.primary.main
      : theme.palette.primary.main};
  border: ${({ theme, selected }): string =>
    `1px solid ${selected ? theme.palette.primary.main : 'transparent'}`};
  > hr {
    width: 100%;
  }
  transition: all 110ms ease-in-out;
  &:hover {
    box-shadow: ${({ theme, hover }): string =>
      (hover && theme.shadows[2]) || '0'};
  }
  box-shadow: ${({ theme, selected, elevation }): string =>
    selected ? theme.shadows[3] : theme.shadows[elevation]};
  text-align: ${({ alignCard }): string => alignCard};

  color: ${({ theme, selected }): string =>
    selected ? theme.palette.common.white : theme.palette.common.black};

  ${CardFooter} {
    justify-content: ${({ alignCard }): string => alignCard};
  }
  ${Title}, ${TextFooter}, ${TextFooter}, ${TextContent} {
    text-align: ${({ alignCard }): string => alignCard};
    color: ${({ theme, cardtype }): string =>
      (cardtype === 'secondary' && `${theme.palette.common.white}`) ||
      'inherit'};
  }
  ${Title}, ${TextFooter}, ${TextFooter}, ${TextContent} {
    color: ${({ theme, colorFull }): string =>
      (colorFull && `${theme.palette.common.white}`) || 'inherit'};
  }
  /* ${CardFooter} {
    background-color: ${({ theme, cardtype, colorFull }): string =>
    (cardtype === 'secondary' && `${theme.palette.primary.main}`) || ''};
      
  } */
`
