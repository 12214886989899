import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { usePageTitleContext } from 'src/context/PageTitleHook'
import { Audit } from 'src/components/Audit'
import Category from './Category'
import Document from './Document'
import Jurisdiction from './Jurisdiction'
import Question from './Question'
import Fillable from './Fillable'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ width: '100%', padding: 8 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.defaultProps = {
  children: null,
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    minHeight: 224,
  },
  tabs: {
    minWidth: 160,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))

const DocumentSetupMain: React.FC = () => {
  const classes = useStyles()
  const { setTitle } = usePageTitleContext()
  const [tabSelected, setTabSelected] = React.useState(0)

  const handleTabChange = (event: any, newTab: number) => {
    setTabSelected(newTab)
  }

  useState(() => {
    setTitle('Document Setup')
  })

  return (
    <Box padding={4} mt={4}>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="subtitle1">Document Setup</Typography>
        </Grid>
      </Grid>
      <Paper>
        <div className={classes.root} style={{ marginTop: 6 }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabSelected}
            onChange={handleTabChange}
            className={classes.tabs}
          >
            <Tab data-cy="tab-category" label="Category" {...a11yProps(0)} />
            <Tab
              data-cy="tab-jurisdiction"
              label="Jurisdiction"
              {...a11yProps(1)}
            />
            <Tab data-cy="tab-document" label="Document" {...a11yProps(2)} />
            <Tab data-cy="tab-question" label="Question" {...a11yProps(3)} />
            <Tab data-cy="tab-audit" label="Audit" {...a11yProps(4)} />
            <Tab
              data-cy="tab-fillable"
              label="Fillable Forms"
              {...a11yProps(5)}
            />
          </Tabs>
          <TabPanel value={tabSelected} index={0}>
            <Category />
          </TabPanel>
          <TabPanel value={tabSelected} index={1}>
            <Jurisdiction />
          </TabPanel>
          <TabPanel value={tabSelected} index={2}>
            <Document />
          </TabPanel>
          <TabPanel value={tabSelected} index={3}>
            <Question />
          </TabPanel>
          <TabPanel value={tabSelected} index={4}>
            <Audit origin="document-setup" />
          </TabPanel>
          <TabPanel value={tabSelected} index={5}>
            <Fillable />
          </TabPanel>
        </div>
      </Paper>
    </Box>
  )
}

export default DocumentSetupMain
