import React, { useEffect, useState } from 'react'
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import TabPanel from '@material-ui/lab/TabPanel'
import TabContext from '@material-ui/lab/TabContext'
import Close from '@material-ui/icons/Close'

import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined'
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined'
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined'

import DocumentFiles from './DocumentFiles'
import CommentsMain from './CommentsMain'
import HistoryMain from './HistoryMain'
import AdditionalDocumentMain from './AdditionalDocumentMain'
import { DialogTitleStyled } from '../../../pages/NewDocumentRegistration/style'

interface DocumentDetailProps {
  documentData: any
  documentRequest: number
  open: boolean
  close: () => void
  selectTab: string
  isRegistration: boolean
  approval: boolean
}

const DocumentDetail: React.FC<DocumentDetailProps> = ({
  documentData,
  open,
  close,
  documentRequest,
  selectTab,
  isRegistration,
  approval,
}: DocumentDetailProps) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [value, setValue] = React.useState(selectTab || '1')
  useEffect(() => {
    setOpenDialog(open)
  }, [open])

  const onCloseDialog = () => close()

  const handleChangeTab = (event: any, newValue: any) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (selectTab !== '') {
      setValue(selectTab)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Dialog
        PaperProps={{
          style: { width: 850, height: 600 },
        }}
        open={openDialog}
        onClose={onCloseDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        maxWidth="md"
      >
        <DialogTitleStyled id="confirm-dialog-title">
          <Typography
            variant="body1"
            style={{
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 400,
              lineHeight: 1.2,
              textAlign: 'center',
            }}
          >
            {approval
              ? 'Approval Documents'
              : documentData?.name.length > 53
              ? `${documentData?.name.substring(0, 50)}...`
              : documentData?.name}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseDialog}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <TabContext value={value}>
            <Tabs
              value={value}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChangeTab}
              aria-label="detail tabs"
              style={{
                borderBottom: '1px solid #e8e8e8',
              }}
            >
              <Tab
                label={
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                  >
                    <InsertDriveFileOutlinedIcon />
                    <Box ml={2}>Files</Box>
                  </Box>
                }
                value="1"
              />
              {isRegistration && (
                <Tab
                  label={
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      <CommentOutlinedIcon />
                      <Box ml={2}>Comments</Box>
                    </Box>
                  }
                  value="2"
                />
              )}

              {isRegistration && (
                <Tab
                  label={
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      <HistoryOutlinedIcon />
                      <Box ml={2}>History</Box>
                    </Box>
                  }
                  value="3"
                />
              )}
              {isRegistration && (
                <Tab
                  label={
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                    >
                      <NoteAddOutlinedIcon />

                      <Box ml={2}>Requested Documents</Box>
                    </Box>
                  }
                  value="4"
                />
              )}
            </Tabs>
            <TabPanel value="1">
              <DocumentFiles
                documentRequestId={documentRequest}
                documentData={documentData}
                isRegistration={isRegistration}
                approval={approval}
              />
            </TabPanel>
            <TabPanel value="2">
              <CommentsMain
                documentRequestId={documentRequest}
                documentData={documentData}
              />
            </TabPanel>
            <TabPanel value="3">
              <HistoryMain documentRequestId={documentRequest} />
            </TabPanel>
            <TabPanel value="4" style={{ height: 'calc(100% - 56px)' }}>
              <AdditionalDocumentMain
                documentRequestId={documentRequest}
                documentData={documentData}
              />
            </TabPanel>
          </TabContext>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DocumentDetail
