import { format, isSameDay } from 'date-fns'
import { formatDateAndTimeLocal } from './date'

interface Options {
  min: number
  max: number
  opacity: number
}
export const dynamicColors = (options: Options): string => {
  const newOptions = {
    ...options,
  }
  const { min, max, opacity } = newOptions
  const r = Math.floor(Math.random() * (max - min + 1)) + min
  const g = Math.floor(Math.random() * (max - min + 1)) + min
  const b = Math.floor(Math.random() * (max - min + 1)) + min
  return `rgba(${r},${g},${b}, ${opacity})`
}
export const getCountryCurrency = (country: string | undefined): string => {
  let currency = ''

  switch (country) {
    case 'CA':
      currency = 'CAD'
      break
    case 'US':
      currency = '$'
      break
    default:
      currency = '$'
  }
  return currency
}

export const isUkCountry = (country: string | undefined) => {
  return country !== undefined ? country.toUpperCase() === 'UK' : false
}

export const getStateDropDownName = (country: string | undefined): string => {
  if (!country) return 'State'

  switch (country.toLowerCase()) {
    case 'uk':
      return 'County'
    case 'ca':
      return 'Province'
    default:
      return 'State'
  }
}

export const getAcronym = (name: string) => {
  const words = name.split(' ')

  if (words.length > 1) {
    let acronym = ''

    words.forEach((f) => (acronym += f[0]?.toUpperCase() ?? ''))

    return acronym
  }
  return name
}
export interface FilesToUpload {
  section: string
  file: File
}

export const getDateLabel = (date: string) => {
  const commentDate = new Date(date)
  const localDate = new Date()

  if (isSameDay(commentDate, localDate)) {
    return format(commentDate, 'p')
  }

  return formatDateAndTimeLocal(commentDate)
}

export const stringToColor = (string: string) => {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export const stringAvatar = (name: string) => {
  const splittedName = name.split(' ')

  return {
    style: {
      backgroundColor: stringToColor(name),
    },
    children: `${splittedName[0][0]}${
      splittedName.length > 1 ? name.split(' ')[1][0] : ''
    }`,
  }
}
