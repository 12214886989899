import React, { useEffect } from 'react'
import AuthService from 'src/configs/AuthService'

export const SignInSilent: React.FC = () => {
  useEffect(() => {
    const authService = new AuthService()
    authService.renewTokenCallback()
  }, [])

  return <div />
}
