import React, { useContext, useEffect, useState } from 'react'
import { Box, Grid, MenuItem, TextField, Typography } from '@material-ui/core'

import { useQuery } from 'react-query'
import axios from 'axios'
import { AuthContext } from 'src/context/AuthenticationContext'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'

import {
  getBusinessesQuery,
  getBusinessesRelated,
  getBuyerQuery,
} from 'src/api/operations/get/business'
import { ICategory, ICertificationPeriod } from 'src/api/models'
import { useLocation } from 'react-router-dom'
import { BusinessContext } from 'src/context/BusinessContext'
import { CategoriesTabs } from 'src/components/CategoryTabs'
import { DocumentCardMain } from 'src/components/DocumentCardMain'
import { CertificationPeriodContext } from 'src/context/CertificationPeriodContext'
import Loader from 'src/components/Loader'
import Skeleton from '@material-ui/lab/Skeleton'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'

const ThirdPartyRepository: React.FC = () => {
  const [selectedBusinessRelated, setSelectedBusinessRelated] = useState<any>()
  const [certifiedBusinesses, setCertifiedBusinesses] = useState<any[]>()

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [businesses, setBusinesses] = useState<any[]>()

  const [availableBusinesses, setAvailableBusinesses] = useState<any[]>()
  const [availableBusinessesRelated, setAvailableBusinessesRelated] =
    useState<any[]>()
  const [availablePeriods, setAvailablePeriods] = useState<any[]>()

  const { userPermissions, profileClient } = useContext(AuthContext)
  const [selectedCertificationPeriod, setSelectedCertificationPeriod] =
    useState<ICertificationPeriod>({
      id: null,
      certificationDate: 'Select a period',
    })
  const userToken = GetAccessToken()
  const { search } = useLocation()

  const queryString = new URLSearchParams(search)
  const categoryId = queryString.get('category')

  const businessId = queryString.get('businessId')
  queryString.delete('businessId')

  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const ability = useContext(AbilityContext)

  const { isLoading: loadingBusinesses } = useQuery({
    queryKey: ['getbusinesses'],
    queryFn: async () => {
      return axios({
        url: process.env.REACT_APP_CONTROLPANEL,
        method: 'post',
        headers: {
          Authorization: userToken,
        },
        data: {
          query: `${getBusinessesQuery(
            profileClient ? profileClient.Id : null
          )}`,
        },
      }).then((result: any) => {
        setBusinesses(result.data.data.getBusinesses.businesses)

        const available = getAvailableBusinesses(
          result.data.data.getBusinesses.businesses
        )
        setAvailableBusinesses(available)
      })
    },
    cacheTime: 0,
  })

  const buyerTypeQuery = useQuery({
    enabled: !!selectedBusinessRelated && selectedBusinessRelated === 'Buyer',
    queryKey: ['buyerType', selectedBusinessRelated],
    queryFn: async () => {
      return axios({
        url: process.env.REACT_APP_CONTROLPANEL,
        method: 'post',
        headers: {
          Authorization: userToken,
        },
        data: {
          query: `${getBuyerQuery(selectedBusinessRelated.id)}`,
        },
      }).then((result: any) => {
        return result.data.data.getBuyer.buyerType
      })
    },
    cacheTime: 0,
  })

  const { isLoading: loadingCertifiedBusiness } = useQuery({
    queryKey: ['certifiedBusinessQuery'],
    queryFn: async () => {
      return axios({
        url: `${BACKEND_URL}/certification/getCertifiedBusinesses`,
        method: 'get',
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setCertifiedBusinesses(result.data)
      })
    },
    cacheTime: 0,
  })

  const { isLoading: loadingRelatedBusiness } = useQuery({
    enabled:
      availableBusinesses && availableBusinesses?.length > 0 && !isInternal,
    queryKey: ['businessesRelated', availableBusinesses],
    queryFn: async () => {
      const ids: string[] = []

      availableBusinesses?.map((r) => ids.push(r.id))

      const result = await getBusinessesRelated(
        JSON.stringify(ids),
        userPermissions.type.toLowerCase()
      )
      setAvailableBusinessesRelated(
        getAvailableBusinessesRelated(result.businesses)
      )
    },
    cacheTime: 0,
  })

  const getAvailableBusinesses = (values: any[]) => {
    return values.filter(
      (f: any) => f.type.toLowerCase() === userPermissions.type.toLowerCase()
    )
  }

  const categoryQuery = useQuery({
    enabled: !!selectedBusinessRelated && !buyerTypeQuery.isLoading,
    queryKey: ['categoryType', selectedBusinessRelated],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/category`,
        headers: {
          Authorization: userToken,
        },
        params: {
          isEnabled: true,
          businessType: selectedBusinessRelated.businessType,
          buyerType: buyerTypeQuery ? buyerTypeQuery.data : '',
        },
      }).then((result: any) => {
        return result.data.categories
      })
    },
    cacheTime: 0,
  })

  const categories: ICategory[] = categoryQuery.data
  const categorySelected = categories?.find(
    (x) => x.id.toString() === categoryId
  )

  const getAvailableBusinessesRelated = (values: any[]) => {
    return values?.filter((f: any) => certifiedBusinesses?.includes(f.id))
  }

  const noCategoriesRegistered =
    categories !== undefined && categories.length === 0

  const { isLoading: loadingCertificationPeriod } = useQuery({
    enabled: !!selectedBusinessRelated,
    queryKey: ['getCertificationPeriodQuery', selectedBusinessRelated],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/actions/certificationperiod/${selectedBusinessRelated.id}`,
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setAvailablePeriods(result.data)
        setSelectedCertificationPeriod(result.data[0])
      })
    },
    cacheTime: 0,
  })

  useEffect(() => {
    if (
      businessId &&
      availableBusinessesRelated &&
      availableBusinessesRelated?.length > 0
    ) {
      const selected = availableBusinessesRelated?.find(
        (r: any) => r.id === businessId
      )

      if (selected) {
        setSelectedBusinessRelated(selected)
      }
    }
  }, [availableBusinessesRelated, businessId])

  const loadingPage =
    loadingCertificationPeriod ||
    loadingCertifiedBusiness ||
    loadingBusinesses ||
    categoryQuery.isLoading ||
    loadingRelatedBusiness

  const readOnly =
    ['Terminated - Out of Business', 'Terminated'].includes(
      selectedBusinessRelated?.status
    ) ||
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_ViewAccess,
      'any'
    )

  return (
    <>
      <CertificationPeriodContext.Provider value={selectedCertificationPeriod}>
        <Box padding={4} mt={4}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Third Party Repository
              </Typography>
            </Grid>
          </Grid>
          <Box
            style={{ width: '100%' }}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            {loadingPage && <Loader />}
            <Box display="flex">
              {loadingPage ? (
                <Skeleton
                  variant="rect"
                  height={50}
                  width="250px"
                  style={{ marginLeft: 3 }}
                />
              ) : (
                availableBusinesses &&
                availableBusinesses.length > 0 &&
                availableBusinessesRelated &&
                availableBusinessesRelated.length > 0 && (
                  <Grid
                    item
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      verticalAlign: 'center',
                    }}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      flexDirection="column"
                    >
                      <Box
                        ml={3}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <TextField
                          select
                          id="select-business-related"
                          value={selectedBusinessRelated?.id ?? ''}
                          fullWidth
                          label={
                            userPermissions.type.toLowerCase() === 'seller'
                              ? 'Buyer'
                              : 'Seller'
                          }
                          style={{ minWidth: '250px' }}
                        >
                          <MenuItem
                            value=""
                            onClick={() => {
                              setSelectedBusinessRelated(null)
                            }}
                          >
                            <em>
                              {`Select ${
                                userPermissions.type.toLowerCase() === 'seller'
                                  ? 'buyer'
                                  : 'seller'
                              }`}
                            </em>
                          </MenuItem>

                          {availableBusinessesRelated &&
                            availableBusinessesRelated?.map((business: any) => {
                              return (
                                <MenuItem
                                  key={business.id}
                                  value={business.id}
                                  onClick={() => {
                                    setSelectedBusinessRelated(business)
                                  }}
                                >
                                  {isInternal
                                    ? `${business.name} - ${business.type}`
                                    : business.name}
                                </MenuItem>
                              )
                            })}
                        </TextField>
                      </Box>
                    </Box>
                  </Grid>
                )
              )}
              {loadingPage ? (
                <Skeleton
                  variant="rect"
                  height={50}
                  width="150px"
                  style={{ marginLeft: 3 }}
                />
              ) : (
                availableBusinesses &&
                availableBusinesses.length > 0 && (
                  <Grid
                    item
                    style={{
                      marginLeft: 10,
                      display: 'flex',
                      alignItems: 'center',
                      verticalAlign: 'center',
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                      >
                        <TextField
                          disabled={
                            !selectedBusinessRelated ||
                            selectedBusinessRelated === null ||
                            selectedBusinessRelated === ''
                          }
                          select
                          id="select-certification-date"
                          value={selectedCertificationPeriod.certificationDate}
                          style={{ minWidth: '115px' }}
                          label="Certification Period"
                        >
                          {availablePeriods?.map(
                            (item: ICertificationPeriod) => {
                              return (
                                <MenuItem
                                  key={item.id}
                                  value={item.certificationDate}
                                  onClick={() => {
                                    setSelectedCertificationPeriod(item)
                                  }}
                                >
                                  {item.certificationDate}
                                </MenuItem>
                              )
                            }
                          )}
                        </TextField>
                      </Box>
                    </Box>
                  </Grid>
                )
              )}
            </Box>
          </Box>
          <BusinessContext.Provider value={selectedBusinessRelated}>
            <Grid style={{ marginTop: 10 }} item xs={12}>
              {categories && (
                <CategoriesTabs
                  categoryData={categories}
                  origin="thirdPartyRepo"
                />
              )}

              {selectedCertificationPeriod.id !== null &&
                !noCategoriesRegistered &&
                availableBusinesses &&
                availableBusinesses.length > 0 && (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12} style={{ marginTop: '20px' }}>
                        {categoryId &&
                          selectedBusinessRelated &&
                          categorySelected && (
                            <DocumentCardMain
                              categoryData={categorySelected}
                              businessUsers={[]}
                              filteredUsers={[]}
                              origin="thirdPartyRepo"
                              recertification={false}
                              disableItems={readOnly}
                            />
                          )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </BusinessContext.Provider>
        </Box>
      </CertificationPeriodContext.Provider>
    </>
  )
}

export default ThirdPartyRepository
