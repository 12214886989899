import styled from 'styled-components'

import { ContainerFluid } from 'src/styles/layout'

export const ComplianceCardSection = styled(ContainerFluid)`
  padding: ${({ theme }): string => theme.spacing(3)};
  background-color: ${({ theme }): string => theme.debtColors.white};
  > .breadcrumb-section {
    margin-bottom: ${({ theme }): string => theme.spacing(4)};
  }
`
