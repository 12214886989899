export const getPermissionsValues = (
  permissionCodes: string[],
  encodedPermissions: string
) => {
  let codes = ''

  permissionCodes.map((item) => (codes = codes.concat(`"${item}",`)))

  codes = codes.substring(0, codes.length - 1)

  return `query  {
    checkPermissionValues(
      permissionCodes: [${codes}]
      encodedPermissions: "${encodedPermissions}"
    ) {
      permissions {
        code
        value
      }
    }
  }
  `
}
