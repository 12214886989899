import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Paper,
  TextField,
} from '@material-ui/core'
import React, { useRef, useState } from 'react'
import {
  GridCellProps,
  GridColumn as KendoGridColumn,
} from '@progress/kendo-react-grid'
import { StyledKendoGrid as KendoGrid } from 'src/styles/kendoGridStyle'
import { formatDate } from 'src/utils/date'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { GetAccessToken } from 'src/api/axios/helper'
import { BACKEND_URL } from 'src/api/axios/api'
import axios from 'axios'
import hardcodeData from 'src/utils/hardcodeData'
import { State } from '@progress/kendo-data-query'
import { ColumnMenu } from 'src/components/Filters/HeaderFilter'

const Reports: React.FC = () => {
  const [reportTypeSelected, setReportTypeSelected] = useState<string>('')
  const [businessTypeSelected, setBusinessTypeSelected] = useState<string>('')
  const [jurisdictionTypeSelected, setJurisdictionTypeSelected] =
    useState<string>('')
  const [isLicense, setIsLicense] = useState<boolean>(false)
  const [days, setDays] = useState<number>(30)
  const [reportData, setReportData] = useState<any>([])
  const grid = useRef<any>(null)

  const { enqueueSnackbar } = useSnackbar()
  const notistackError = notistackOptions('error')
  const userToken = GetAccessToken()
  const [total, setTotal] = useState(0)
  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 10,
    filter: undefined,
    sort: undefined,
  })

  const reportTypes = [
    {
      type: 'Expired Licenses This Year',
      value: 'ExpiredLicensesLicensesThisYear',
    },
    { type: 'Expired Licenses', value: 'ExpiredLicenses' },
    { type: 'Expiring Documents', value: 'ExpiringDocuments' },
  ]

  const jurisdictionTypes = [
    { type: 'City', value: 'city' },
    { type: 'Country', value: 'country' },
    { type: 'State', value: 'state' },
  ]

  const handleReportTypeChange = (reportType: string) => {
    setReportTypeSelected(reportType)
    setReportData([])
  }

  const handleBusinessTypeChange = (businessType: string) => {
    setBusinessTypeSelected(businessType)
  }

  const handleJurisdictionTypeChange = (jurisdictionType: string) => {
    setJurisdictionTypeSelected(jurisdictionType)
  }

  const handleSubmit = (gridStateParams: State | null) => {
    let requestData = {}

    if (reportTypeSelected === 'ExpiredLicensesLicensesThisYear') {
      requestData = {
        pagination: encodeURIComponent(
          JSON.stringify(gridStateParams || gridState)
        ),
      }
    } else if (reportTypeSelected === 'ExpiredLicenses') {
      requestData = {
        businessType: businessTypeSelected,
        jurisdictionType: jurisdictionTypeSelected,
        pagination: encodeURIComponent(
          JSON.stringify(gridStateParams || gridState)
        ),
      }
    } else if (reportTypeSelected === 'ExpiringDocuments') {
      requestData = {
        businessType: businessTypeSelected,
        isLicense,
        days,
        pagination: encodeURIComponent(
          JSON.stringify(gridStateParams || gridState)
        ),
      }
    }

    axios({
      method: 'post',
      url: `${BACKEND_URL}/reports/${reportTypeSelected}`,
      params: requestData,
      headers: {
        Authorization: userToken,
      },
    })
      .then((res) => {
        setTotal(res.data.total)
        setReportData(res.data)
      })
      .catch((error) => {
        enqueueSnackbar(error.response.data, notistackError)
      })
  }

  const disableSubmit = () => {
    if (reportTypeSelected === '') return true
    if (reportTypeSelected === 'ExpiredLicenses') {
      if (!businessTypeSelected || !jurisdictionTypeSelected) return true
    } else if (reportTypeSelected === 'ExpiringDocuments') {
      if (!businessTypeSelected || days === 0) return true
    }

    return false
  }

  const GridColumns = () => {
    if (reportTypeSelected === 'ExpiredLicenses') {
      const columns: any[] = [
        { field: 'name', title: 'Jurisdiction' },
        { field: 'qtyLicensesExpired', title: 'Quantity Licenses Expired' },
      ]
      return columns
    }

    const columns: any[] = [
      { field: 'businessName', title: 'Business Name' },
      { field: 'categoryName', title: 'Category Name' },
      { field: 'documentName', title: 'Document Name' },
      {
        field: 'expirationDateUtc',
        title: 'Expiration Date',
        filter: 'date',
        cell: (props: GridCellProps) => {
          const field = props.dataItem['expirationDateUtc'] || null
          return <td>{field === null ? '' : formatDate(field)}</td>
        },
      },
      { field: 'originalFileName', title: 'File Name' },
    ]

    return columns
  }

  return (
    <>
      <Box flexDirection="row" display="flex">
        <Box>
          <TextField
            id="select-report-type"
            select
            label="Report Type"
            style={{ width: '180px' }}
            name="reportId"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => handleReportTypeChange(e.target.value)}
          >
            <MenuItem value="">
              <em>Select a report type</em>
            </MenuItem>
            {reportTypes.map((rt: any) => {
              return (
                <MenuItem key={`${rt.type}`} value={rt.value}>
                  {rt.type}
                </MenuItem>
              )
            })}
          </TextField>
        </Box>
        <Box
          ml={4}
          hidden={
            !reportTypeSelected ||
            reportTypeSelected === 'ExpiredLicensesLicensesThisYear'
          }
        >
          <TextField
            id="select-business-type"
            select
            label="Business Type"
            style={{ width: '100px' }}
            name="businessTypeId"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => handleBusinessTypeChange(e.target.value)}
          >
            <MenuItem value="">
              <em>Select a business type</em>
            </MenuItem>
            {hardcodeData.getBusinessType().map((i: any) => {
              return (
                <MenuItem key={`${i.id}`} value={i.id}>
                  {i.name}
                </MenuItem>
              )
            })}
          </TextField>
        </Box>
        <Box
          ml={4}
          hidden={
            !reportTypeSelected ||
            reportTypeSelected === 'ExpiredLicensesLicensesThisYear' ||
            reportTypeSelected === 'ExpiringDocuments'
          }
        >
          <TextField
            id="select-jurisdiction-type"
            select
            label="Jurisdiction Type"
            style={{ width: '120px' }}
            name="jurisdictionTypeId"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => handleJurisdictionTypeChange(e.target.value)}
          >
            <MenuItem value="">
              <em>Select a jurisdiction type</em>
            </MenuItem>
            {jurisdictionTypes.map((i: any) => {
              return (
                <MenuItem key={`${i.type}`} value={i.value}>
                  {i.type}
                </MenuItem>
              )
            })}
          </TextField>
        </Box>
        <Box
          ml={4}
          hidden={
            !reportTypeSelected ||
            reportTypeSelected === 'ExpiredLicensesLicensesThisYear' ||
            reportTypeSelected === 'ExpiredLicenses'
          }
        >
          <TextField
            type="number"
            style={{ width: '200px' }}
            label="Documents expiring in (days)"
            value={days}
            // eslint-disable-next-line radix
            onChange={(e) => setDays(parseInt(e.target.value, 0))}
          />
        </Box>
        <Box
          ml={4}
          hidden={
            !reportTypeSelected ||
            reportTypeSelected === 'ExpiredLicensesLicensesThisYear' ||
            reportTypeSelected === 'ExpiredLicenses'
          }
        >
          <FormControlLabel
            control={<Checkbox name="check" />}
            label="Is License"
            value={isLicense}
            onChange={(e, checked) => setIsLicense(checked)}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit(null)}
          disabled={disableSubmit()}
        >
          Submit
        </Button>
      </Box>
      <Box mt={4}>
        <Paper>
          <KendoGrid
            ref={grid}
            style={{ height: '600px' }}
            data={reportData.data || []}
            skip={gridState.skip}
            take={gridState.take}
            pageSize={gridState.take}
            filter={gridState.filter}
            sort={gridState.sort}
            sortable
            pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
            total={total}
            onDataStateChange={(e) => {
              setGridState(e.dataState)
              if (reportData.data) {
                handleSubmit(e.dataState)
              }
            }}
          >
            {GridColumns().reduce((acc, item, idx) => {
              const component = [...acc]

              component.push(
                <KendoGridColumn
                  key={item.field + item.title}
                  field={item.field}
                  title={item.title}
                  format={item.format}
                  cell={item.cell}
                  filter={item.filter || 'text'}
                  columnMenu={ColumnMenu}
                />
              )

              return component
            }, [])}
          </KendoGrid>
        </Paper>
      </Box>
    </>
  )
}

export default Reports
