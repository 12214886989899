/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import {
  GridCellProps,
  GridColumn as KendoGridColumn,
} from '@progress/kendo-react-grid'
import { StyledKendoGrid as KendoGrid } from 'src/styles/kendoGridStyle'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useQuery } from 'react-query'
import { BACKEND_URL } from 'src/api/axios/api'
import hardcodeData from 'src/utils/hardcodeData'
import { State } from '@progress/kendo-data-query'
import { GetAccessToken } from 'src/api/axios/helper'
import { AuthContext } from 'src/context/AuthenticationContext'
import { AbilityContext, Can } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { Close } from '@material-ui/icons'
import Loader from 'src/components/Loader'
import FillableCommandCell from 'src/components/GridCommandCell/FillableCommandCell'
import { DialogTitleStyled } from '../NewDocumentRegistration/style'

interface IFillable {
  categoryId: number | undefined
  type: string | undefined
  name: string
  description: string
  aliasName: string
  isRequired: boolean
  isEnabled: boolean
  businessTypeName: string[]
  validateBuyerTypeName: boolean
  buyerTypeName: string[]
  servicerType: string[]
}

const Fillable: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar()
  const notistackSucces = notistackOptions('success')
  const notistackError = notistackOptions('error')
  const [data, setData] = useState<any[]>([])
  const [category, setCategory] = useState<any[]>([])

  const [loading, setLoading] = useState<boolean>(true)
  const [openAddDialog, setOpenAddDialog] = useState(false)

  const userToken = GetAccessToken()
  const { userPermissions } = useContext(AuthContext)
  const ability = useContext(AbilityContext)

  const [total, setTotal] = useState(0)
  const canManage =
    userPermissions.type === 'internal' &&
    ability.can(
      PermissionCodeAccess.ComplianceManagementSystem_Basic_ManageDocumentSetup,
      'any'
    )
  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 5,
    filter: undefined,
    sort: undefined,
  })

  const getData = () => {
    axios({
      method: 'get',
      url: `${BACKEND_URL}/fillableForm`,
      params: { pagination: encodeURIComponent(JSON.stringify(gridState)) },
      headers: {
        Authorization: userToken,
      },
    }).then((result: any) => {
      setData(result.data.fillableForms)
      setTotal(result.data.total)
      setLoading(false)
    })
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridState])

  const { isLoading: loadingCategory } = useQuery({
    queryKey: ['getCategories'],
    queryFn: async () =>
      axios({
        method: 'get',
        url: `${BACKEND_URL}/category`,
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setCategory(result.data.categories)
      }),
    cacheTime: 0,
  })

  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialValues, setInitialValues] = useState<IFillable>({
    name: '',
    type: undefined,
    categoryId: undefined,
    description: '',
    isEnabled: false,
    aliasName: '',
    isRequired: false,
    businessTypeName: [],
    buyerTypeName: [],
    validateBuyerTypeName: false,
    servicerType: [],
  })

  const inputDataFormSchema = Yup.object().shape({
    name: Yup.string().trim().required('Required'),
    type: Yup.string().trim().required('Required'),
    categoryId: Yup.number().typeError('Required').required('Required'),
    description: Yup.string(),
    isEnabled: Yup.boolean().required('Required'),
    isRequired: Yup.boolean().required('Required'),
    businessTypeName: Yup.array().min(1, 'Required'),
    validateBuyerTypeName: Yup.boolean(),
    buyerTypeName: Yup.array().when(
      'businessTypeName',
      (arrayData: string[], schema: any) =>
        arrayData.includes('Buyer') ? schema.min(1, 'Required') : schema
    ),
    servicerType: Yup.array().when(
      'businessTypeName',
      (arrayData: string[], schema: any) =>
        arrayData.includes('Servicer') ? schema.min(1, 'Required') : schema
    ),
  })

  const validateBusinessTypes = (values: any) => {
    const categoryIndex = category.findIndex((f) => f.id === values.categoryId)

    const currentCategory = category[categoryIndex]

    const businessTypeExcept = values.businessTypeName.filter(
      (item: any) => !currentCategory.businessTypeName.includes(item)
    )

    const buyerTypeExcept = values.buyerTypeName.filter(
      (item: any) => !currentCategory.buyerTypeName.includes(item)
    )

    const servicerTypeExcept = values.servicerType.filter(
      (item: any) => !currentCategory.servicerType.includes(item)
    )

    const returnExceptTypes = businessTypeExcept.concat(
      buyerTypeExcept,
      servicerTypeExcept
    )

    if (returnExceptTypes.length > 0) {
      return 'The category that this form belong, does not have "Business Type" selected in the form.'
    }

    return ''
  }

  const inputDataForm = useFormik({
    initialValues,
    validationSchema: inputDataFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      const validateBusiness = validateBusinessTypes(values)
      if (validateBusiness !== '') {
        setSubmitting(false)
        enqueueSnackbar(validateBusiness, notistackError)
      } else {
        setLoading(true)
        const requestValues: any = {
          name: values.name || null,
          categoryId:
            typeof values.categoryId === 'number' ? values.categoryId : null,
          description: values.description || null,
          isEnabled: values.isEnabled,
          isRequired: values.isRequired,
          aliasName: values.aliasName || null,
          type: values.type || null,
          businessTypeName: values.businessTypeName || null,
          buyerTypeName: values.buyerTypeName || null,
          servicerType: values.servicerType || null,
        }

        axios({
          method: 'post',
          url: `${BACKEND_URL}/fillableForm`,
          data: requestValues,
          headers: {
            Authorization: userToken,
          },
        })
          .then(() => {
            inputDataForm.resetForm({ values: initialValues })
            enqueueSnackbar('New data added', notistackSucces)
            setOpenAddDialog(false)
            setLoading(false)
            getData()
          })
          .catch((error) => {
            enqueueSnackbar(error.response.data, notistackError)
            setLoading(false)
          })

        setSubmitting(false)
      }
    },
  })

  const CommandCell = (props: GridCellProps) => (
    <FillableCommandCell
      dataItem={props.dataItem}
      category={category}
      updateSuccededCallback={() => {
        setLoading(false)
        axios({
          method: 'get',
          url: `${BACKEND_URL}/fillableForm`,
          params: { pagination: gridState },
          headers: {
            Authorization: userToken,
          },
        }).then((result: any) => {
          setData(result.data.fillableForms)
          setTotal(result.data.total)
          setLoading(false)
        })
      }}
      confirmCallback={() => {
        setLoading(true)
        axios({
          method: 'delete',
          url: `${BACKEND_URL}/fillableForm/${props.dataItem.id}`,
          headers: {
            Authorization: userToken,
          },
        })
          .then(() => {
            enqueueSnackbar('Fillable form deleted', notistackSucces)
            setLoading(false)
            getData()
          })
          .catch((error) => {
            enqueueSnackbar(error.response.data, notistackError)
            setLoading(false)
          })
      }}
    />
  )

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  const fillableFormArray: any[] = hardcodeData.getFillableForms()

  return (
    <>
      <form onSubmit={inputDataForm.handleSubmit}>
        <Grid container style={{ height: '100%', width: '100%' }}>
          <Can
            do={
              PermissionCodeAccess.ComplianceManagementSystem_Basic_ManageDocumentSetup
            }
            on="any"
          >
            <Grid container justifyContent="flex-end">
              <Box>
                <Button
                  data-cy="create-button"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    inputDataForm.setFieldValue('businessTypeName', [], false)
                    setOpenAddDialog(true)
                  }}
                  disabled={!canManage}
                >
                  Create New Form
                </Button>
              </Box>
            </Grid>
          </Can>
          <Grid container style={{ marginTop: '24px' }}>
            <Grid item>
              {(loading || loadingCategory) && loadingPanel}
              <KendoGrid
                data={data || []}
                sortable
                style={{
                  height: 'auto',
                  maxHeight: window.innerHeight * 0.6,
                }}
                skip={gridState.skip}
                take={gridState.take}
                pageSize={gridState.take}
                filter={gridState.filter}
                sort={gridState.sort}
                pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
                total={total}
                onDataStateChange={(e) => {
                  setGridState(e.dataState)
                }}
              >
                <KendoGridColumn
                  field="id"
                  title="ID"
                  width="80px"
                  cell={(props: GridCellProps) => {
                    const field = props.field ?? ''
                    return (
                      <td style={{ textAlign: 'center' }}>
                        {props.dataItem[field]}
                      </td>
                    )
                  }}
                />
                <KendoGridColumn field="categoryName" title="Category" />
                <KendoGridColumn field="name" title="Name" />
                <KendoGridColumn field="type" title="Type" />
                <KendoGridColumn field="aliasName" title="Alias Name" />
                <KendoGridColumn field="description" title="Description" />
                <KendoGridColumn
                  field="businessTypeName"
                  title="Business Type"
                  sortable={false}
                  width="160px"
                />
                <KendoGridColumn
                  field="isEnabled"
                  title="Enabled"
                  width="100px"
                  cell={(props: GridCellProps) => {
                    const field = props.field ?? ''
                    return <td>{props.dataItem[field] ? 'Yes' : 'No'}</td>
                  }}
                />
                <KendoGridColumn
                  field="isRequired"
                  title="Required"
                  width="100px"
                  cell={(props: GridCellProps) => {
                    const field = props.field ?? ''
                    return <td>{props.dataItem[field] ? 'Yes' : 'No'}</td>
                  }}
                />

                <KendoGridColumn field="buyerTypeName" title="Buyer Type" />

                {canManage && !loading && (
                  <KendoGridColumn cell={CommandCell} />
                )}
              </KendoGrid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          open={openAddDialog}
          onClose={() => {
            setOpenAddDialog(false)
            inputDataForm.resetForm({ values: initialValues })
          }}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
        >
          <form onSubmit={inputDataForm.handleSubmit}>
            <DialogTitleStyled id="form-dialog-title">
              <Typography
                variant="h1"
                style={{ textAlign: 'center', color: 'white' }}
              >
                Fillable Form
              </Typography>

              <IconButton
                aria-label="close"
                onClick={() => {
                  inputDataForm.resetForm({ values: initialValues })
                  setOpenAddDialog(false)
                }}
                style={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: 'white',
                }}
              >
                <Close />
              </IconButton>
            </DialogTitleStyled>
            <DialogContent>
              {loading && <Loader />}
              <Grid
                container
                style={{
                  height: '100%',
                  width: '100%',
                  maxWidth: '400px',
                }}
              >
                <Grid item xs={12} md={12}>
                  <Typography component="div">
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item xs={12} md={6} lg={6}>
                        <Box display="flex">
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                          >
                            <Typography>Enabled</Typography>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                          >
                            <Switch
                              data-cy="dialog-disable-enabled-selector"
                              checked={inputDataForm.values.isEnabled}
                              onChange={inputDataForm.handleChange}
                              name="isEnabled"
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={6} lg={6}>
                        <Box display="flex">
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                          >
                            <Typography>Required</Typography>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                          >
                            <Switch
                              data-cy="dialog-required-not-required-selector"
                              checked={inputDataForm.values.isRequired}
                              onChange={inputDataForm.handleChange}
                              name="isRequired"
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Typography>
                </Grid>

                <Grid item xs={12} md={12} style={{ paddingTop: '5px' }}>
                  <TextField
                    data-cy="dialog-category-dropdown"
                    id="select-request-type"
                    select
                    label="Category"
                    style={{ width: '100%' }}
                    name="categoryId"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={inputDataForm.handleChange}
                    value={inputDataForm.values.categoryId}
                    error={!!inputDataForm.errors.categoryId}
                    helperText={inputDataForm.errors.categoryId}
                  >
                    <MenuItem value="">
                      <em>Select a category</em>
                    </MenuItem>
                    {category.map((dt: any) => {
                      return (
                        <MenuItem key={dt.name} value={dt.id}>
                          <Typography>{dt.name}</Typography>
                        </MenuItem>
                      )
                    })}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    data-cy="type"
                    id="type"
                    select
                    label="Type"
                    style={{ width: '100%' }}
                    name="type"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={inputDataForm.handleChange}
                    value={inputDataForm.values.type}
                    error={!!inputDataForm.errors.type}
                    helperText={inputDataForm.errors.type}
                  >
                    <MenuItem value="">
                      <em>Select a Type</em>
                    </MenuItem>
                    {fillableFormArray?.map((u: any) => (
                      <MenuItem value={u.value} key={u.value}>
                        {u.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    data-cy="dialog-input-field-name"
                    label="Name"
                    name="name"
                    inputProps={{ maxLength: 100 }}
                    value={inputDataForm.values.name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={inputDataForm.handleChange}
                    error={!!inputDataForm.errors.name}
                    helperText={inputDataForm.errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    data-cy="dialog-input-field-alias-name"
                    label="Alias Name"
                    name="aliasName"
                    inputProps={{ maxLength: 10 }}
                    value={inputDataForm.values.aliasName}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={inputDataForm.handleChange}
                    error={!!inputDataForm.errors.aliasName}
                    helperText={inputDataForm.errors.aliasName}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    data-cy="dialog-input-field-description"
                    label="Description"
                    name="description"
                    inputProps={{ maxLength: 250 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={inputDataForm.values.description}
                    onChange={inputDataForm.handleChange}
                    error={!!inputDataForm.errors.description}
                    helperText={inputDataForm.errors.description}
                  />
                </Grid>

                <Grid item xs={12} md={12}>
                  <FormControl>
                    <FormGroup style={{ paddingTop: '15px' }}>
                      <FormLabel
                        data-cy="dialog-business-types-label"
                        component="legend"
                      >
                        Business Types
                      </FormLabel>
                      <FormHelperText style={{ color: 'red' }}>
                        {inputDataForm.errors.businessTypeName}
                      </FormHelperText>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                      >
                        {hardcodeData.getBusinessType().map((x) => {
                          return (
                            <FormControlLabel
                              data-cy={`dialog-checkbox-${x.id}`}
                              key={`checkbox_${x.id}`}
                              label={x.name}
                              control={
                                <Checkbox
                                  name="businessTypeName"
                                  onChange={(e) => {
                                    if (e.target.value === 'Buyer')
                                      inputDataForm.setFieldValue(
                                        'validateBuyerTypeName',
                                        !inputDataForm.values
                                          .validateBuyerTypeName
                                      )
                                    inputDataForm.handleChange(e)
                                  }}
                                  checked={inputDataForm.values.businessTypeName.includes(
                                    x.name
                                  )}
                                  value={x.name}
                                />
                              }
                            />
                          )
                        })}
                      </Box>
                    </FormGroup>
                  </FormControl>
                </Grid>
                {inputDataForm.values.businessTypeName.find(
                  (x) => x === 'Buyer'
                ) && (
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormGroup
                        data-cy="dialog-buyer-type-label"
                        style={{ paddingTop: '12px' }}
                      >
                        <FormLabel component="legend">Buyer Type</FormLabel>
                        <FormHelperText style={{ color: 'red' }}>
                          {inputDataForm.errors.buyerTypeName}
                        </FormHelperText>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                        >
                          <FormControlLabel
                            label="Active"
                            control={
                              <Checkbox
                                data-cy="dialog-buyer-type-active-checkbox"
                                name="buyerTypeName"
                                onChange={inputDataForm.handleChange}
                                value="Active"
                                checked={inputDataForm.values.buyerTypeName.includes(
                                  'Active'
                                )}
                              />
                            }
                          />
                          <FormControlLabel
                            label="Passive"
                            control={
                              <Checkbox
                                data-cy="dialog-buyer-type-passive-checkbox"
                                name="buyerTypeName"
                                onChange={inputDataForm.handleChange}
                                value="Passive"
                                checked={inputDataForm.values.buyerTypeName.includes(
                                  'Passive'
                                )}
                              />
                            }
                          />
                        </Box>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                )}
                {inputDataForm.values.businessTypeName.find(
                  (x) => x === 'Servicer'
                ) && (
                  <Grid item xs={12} md={12}>
                    <FormControl>
                      <FormGroup
                        data-cy="dialog-servicer-type-label"
                        style={{ paddingTop: '12px' }}
                      >
                        <FormLabel component="legend">Servicer Type</FormLabel>
                        <FormHelperText style={{ color: 'red' }}>
                          {inputDataForm.errors.servicerType}
                        </FormHelperText>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                        >
                          <FormControlLabel
                            label="Internal"
                            control={
                              <Checkbox
                                data-cy="dialog-servicer-type-internal-checkbox"
                                name="servicerType"
                                onChange={inputDataForm.handleChange}
                                value="Internal"
                                checked={inputDataForm.values.servicerType.includes(
                                  'Internal'
                                )}
                              />
                            }
                          />
                          <FormControlLabel
                            label="External"
                            control={
                              <Checkbox
                                data-cy="dialog-servicer-type-external-checkbox"
                                name="servicerType"
                                onChange={inputDataForm.handleChange}
                                value="External"
                                checked={inputDataForm.values.servicerType.includes(
                                  'External'
                                )}
                              />
                            }
                          />
                        </Box>
                      </FormGroup>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setOpenAddDialog(false)
                      inputDataForm.resetForm({ values: initialValues })
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    data-cy="dialog-create-button"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!inputDataForm.dirty || !inputDataForm.isValid}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </Dialog>
      </form>
    </>
  )
}

export default Fillable
