import { IconButton, Typography } from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import * as React from 'react'
import { ConfirmDialog } from '../Dialogs'

interface DeleteCommandCellProps {
  dataItem: any
  confirmCallback: () => void
  editCallback: () => void
}

const DeleteCommandCell: React.FC<DeleteCommandCellProps> = ({
  dataItem,
  confirmCallback,
  editCallback,
}: DeleteCommandCellProps) => {
  const [openContactDialog, setOpenContactDialog] = React.useState(false)

  return (
    <>
      <td className="k-command-cell">
        <IconButton
          onClick={() => {
            setOpenContactDialog(true)
          }}
        >
          <Delete />
        </IconButton>
        <IconButton
          onClick={() => {
            editCallback()
          }}
        >
          <Edit />
        </IconButton>
      </td>

      {openContactDialog && (
        <ConfirmDialog
          open={openContactDialog}
          title="Delete"
          description={
            <>
              <Typography variant="body1" color="textSecondary">
                Would you like to delete this item?
              </Typography>
            </>
          }
          closeName="Cancel"
          confirmName="Continue"
          onClose={() => setOpenContactDialog(false)}
          onConfirm={() => {
            confirmCallback()
            setOpenContactDialog(false)
          }}
        />
      )}
    </>
  )
}

export default DeleteCommandCell
