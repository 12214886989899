import React from 'react'
import { Box, LinearProgress } from '@material-ui/core'

const LoaderPage = () => {
  return (
    <Box width="100%">
      <LinearProgress />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <img src="/img/ec-color-logo.svg" width={300} alt="DebtTrader" />
      </Box>
    </Box>
  )
}

export default LoaderPage
