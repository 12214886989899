import React, { createContext, useReducer, Reducer } from 'react'

interface LoadingState {
  loading: boolean
}

interface LoadingAction {
  type: 'SHOW' | 'HIDE'
}

interface GlobalContextProps {
  loading: boolean
  setLoading: (isLoading: boolean) => void
}

const initialState: LoadingState = {
  loading: false,
}

const GlobalContext = createContext<GlobalContextProps>({
  loading: initialState.loading,
  setLoading: () => {},
})

const loadingReducer: Reducer<LoadingState, LoadingAction> = (
  state,
  action
) => {
  switch (action.type) {
    case 'SHOW':
      return { loading: true }
    case 'HIDE':
      return { loading: false }
    default:
      throw new Error()
  }
}
interface IGlobalProvider {
  children: React.ReactNode
}
const GlobalProvider: React.FC<IGlobalProvider> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<LoadingState, LoadingAction>>(
    loadingReducer,
    initialState
  )

  const setLoading = (isLoading: boolean) => {
    if (isLoading) {
      dispatch({ type: 'SHOW' })
    } else {
      dispatch({ type: 'HIDE' })
    }
  }

  return (
    <GlobalContext.Provider value={{ loading: state.loading, setLoading }}>
      {children}
    </GlobalContext.Provider>
  )
}

export { GlobalContext, GlobalProvider }
