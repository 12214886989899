import styled from 'styled-components'

interface DropUploadProps {
  isDragActive?: boolean
  disabled?: boolean
  ref?: any
  size?: 'small' | 'large'
}

export const DropUpload = styled.div<DropUploadProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 3px;
  opacity: ${({ disabled }): number => (!disabled ? 1 : 0.3)};
  border: 1px dashed
    ${({ theme, isDragActive }): string =>
      !isDragActive ? theme.palette.grey[400] : theme.debtColors.debtTea};
  padding: ${({ size }): string =>
    (size === 'small' && '1.2rem 0.9rem') ||
    (size === 'large' && '1.4rem 1.1rem') ||
    '1.5rem 1.2rem'};
  background-color: ${({ theme }): string => theme.debtColors.debtLightBlue};
  > .upload-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: ${({ theme }): string => theme.palette.grey[600]};
    font-size: 0.9rem;
    ${({ theme }): string => theme.breakpoints.up('sm')} {
      font-size: 1.1rem;
      justify-content: flex-start;
      flex-direction: row;
    }
    ${({ theme }): string => theme.breakpoints.up('lg')} {
      font-size: ${({ size }): string =>
        (size === 'small' && '1.2rem') ||
        (size === 'large' && '1.3rem') ||
        '1.4rem'};
    }
    strong {
      color: ${({ theme }): string => theme.debtColors.debtTea};
      text-decoration: underline;
      margin-right: 0.3rem;
    }
    > .upload-icon {
      background-color: ${({ theme }): string =>
        theme.debtColors.debtLightBlue};
      margin-right: 0.7rem;
    }
  }
`

export const LargeDropUpload = styled.div<DropUploadProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 3px;
  opacity: ${({ disabled }): number => (!disabled ? 1 : 0.3)};
  border: 1px dashed
    ${({ theme, isDragActive }): string =>
      !isDragActive ? theme.palette.grey[400] : theme.debtColors.debtTea};
  padding: ${({ size }): string => '1.3rem 0.8rem'};
  width: 200px;
  background-color: ${({ theme }): string => theme.palette.debtLightBlue};
  > .upload-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 0.9rem;
    ${({ theme }): string => theme.breakpoints.up('sm')} {
      font-size: 1.1rem;
      justify-content: flex-start;
      flex-direction: row;
    }
    ${({ theme }): string => theme.breakpoints.up('lg')} {
      font-size: ${({ size }): string =>
        (size === 'small' && '1.2rem') ||
        (size === 'large' && '1.3rem') ||
        '1.4rem'};
    }
    strong {
      color: rgb(83, 152, 221);
      text-decoration: underline;
      margin-right: 0.3rem;
    }
    .upload-icon {
      color: rgb(83, 152, 221);
      margin-right: 0.7rem;
    }
    .upload-text {
      color: ${({ theme }): string => theme.palette.grey[700]};
      font-size: 0.8rem;
    }
  }
`

export const UploadItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 1.2rem;
  .upload-item-info {
    display: flex;
    align-items: center;
  }
  .upload-item-icon {
    margin-right: 0.4rem;
  }
`
