import React, { useContext } from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { AuthContext } from 'src/context/AuthenticationContext'

interface PrivitedRouteProps extends RouteProps {
  children: React.ReactElement
}

const PrivitedRoute: React.FC<PrivitedRouteProps> = ({
  children,
  path,
  exact,
  ...rest
}: PrivitedRouteProps) => {
  const { isAuth } = useContext(AuthContext)

  return (
    <Route
      {...rest}
      path={path}
      exact={exact}
      render={(): React.ReactNode => {
        if (isAuth()) {
          return children
        }
        return <Redirect to="/" />
      }}
    />
  )
}

export default PrivitedRoute
