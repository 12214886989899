import VMasker from 'vanilla-masker'

interface MoneyOptions {
  // Decimal precision -> "90"
  precision?: number

  // Decimal separator -> ".90"
  separator?: string

  // Number delimiter -> "12,345,678"
  delimiter?: string

  // Money unit -> "$ 12,345,678.90"
  unit?: string

  // Money unit -> "12,345,678.90 $"
  suffixUnit?: string

  // Force type only number instead decimal,
  // masking decimals with ".00"
  // Zero cents -> "$ 1,234,567,890.00"
  zeroCents?: boolean
}

const moneyOptionDefault: MoneyOptions = {
  unit: '$',
  separator: '.',
  delimiter: ',',
  suffixUnit: '',
}
const moneyOption: MoneyOptions = {
  separator: '.',
  delimiter: ',',
  suffixUnit: '',
}

const percentOption: MoneyOptions = {
  separator: '.',
  precision: 3,
}

const FactoryVMasker = () => ({
  maskerPercentRx: (value: any = '', options = percentOption) =>
    VMasker.toMoney(value, options),

  maskerPercent: (value: any = '') => {
    return VMasker.toPattern(value, '99.999')
  },
  maskerNumber: (value: any = '') => VMasker.toNumber(value),
  maskMoney: (value: any = '', options = moneyOption) =>
    VMasker.toMoney(value, options),
  maskerMoney: (value: any = '', options = moneyOptionDefault) =>
    VMasker.toMoney(value, options),
  maskPhoneNumber: (value: string = '', country: any = '') => {
    if (country === 'UK') {
      if (!value) return ''
      // eslint-disable-next-line no-param-reassign
      if (!/^[+\d]/.test(value)) value = value.substring(1)
      if (value.length === 1) return value.replace(/[^\d+\s]/g, '')

      const firstCharacter = value[0]
      if (firstCharacter)
        return firstCharacter + value.substring(1).replace(/[^\d\s]/g, '')

      return value.replace(/[^\d\s]/g, '')
    }

    return VMasker.toPattern(value, '(999) 999-9999')
  },
  maskZipCode: (value: any, country: string | undefined) => {
    if (country?.toLowerCase() === 'us')
      return VMasker.toPattern(value, '99999')
    if (country?.toLowerCase() === 'uk') {
      return VMasker.toPattern(value.toUpperCase(), 'SSSSSSS')
    }

    return value
  },
  unmaskMoney: (value: any = '') => value.replace(/[$,]+/g, ''), // Removing dollar sign and comma
})

export const {
  maskerNumber,
  maskerPercent,
  maskerMoney,
  maskMoney,
  maskZipCode,
  maskPhoneNumber,
  unmaskMoney,
  maskerPercentRx,
} = FactoryVMasker()
