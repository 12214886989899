import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Button, Grid, Typography } from '@material-ui/core'
import axios from 'axios'
import { CONTROLPANEL_URL } from 'src/api/axios/api'
import { GetAccessToken } from 'src/api/axios/helper'
import { useQuery } from 'react-query'
import TransferList from '../TransferList/TransferList'
import { Step, StepContent, StepNumber } from './styles'

interface StepperIndicationProps {
  label: string
  active: boolean
  stepNumber: number | string
  onChangeStep: (active: any) => void
}

const StepperIndication: React.FC<StepperIndicationProps> = ({
  label,
  active,
  stepNumber,
  onChangeStep,
}: StepperIndicationProps) => {
  return (
    <>
      <Step onClick={onChangeStep}>
        <StepContent>
          <StepNumber active={active}>{stepNumber}</StepNumber>
          <Typography color={active ? 'textPrimary' : 'textSecondary'}>
            {label}
          </Typography>
        </StepContent>
      </Step>
    </>
  )
}

interface IPortfolioAssetTypes {
  handleChange: (name: any, value: any, setValue: boolean) => void
  inputDataForm: any
  readOnly: boolean
  handleFormFilled: (filled: boolean) => void
}

const PortfolioAssetTypes: React.FC<IPortfolioAssetTypes> = ({
  handleChange,
  inputDataForm,
  readOnly,
  handleFormFilled,
}: IPortfolioAssetTypes) => {
  const userToken = GetAccessToken()
  const [portfolioTypesAvailable, setPortfolioTypesAvailable] = useState<any[]>(
    []
  )
  const [portfolioTypesSelected, setPortfolioTypesSelected] = useState<any[]>(
    []
  )
  const [assetTypesAvailable, setAssetTypesAvailable] = useState<any[]>([])
  const [assetTypesSelected, setAssetTypesSelected] = useState<any[]>([])
  const firstUpdateAssetType = useRef(true)
  const firstUpdatePortfolioType = useRef(true)
  const [completed, setCompleted] = React.useState(new Set<number>())
  const steps = ['Select portfolio types', 'Select asset types']
  const [activeStep, setActiveStep] = React.useState(0)

  const getTotalSteps = (): number => steps.length - 1
  const isLastStep = (): boolean => getTotalSteps() === activeStep

  const handleCompletedAdd = (newActiveStep: number) => {
    const newCompleted = new Set(completed)
    newCompleted.add(newActiveStep)
    setCompleted(newCompleted)
  }

  const handleCompletedRemove = (newActiveStep: number) => {
    const newCompleted = new Set(completed)
    newCompleted.delete(newActiveStep)
    setCompleted(newCompleted)
  }

  const handlePrevious = () => {
    const newActiveStep = activeStep - 1
    setActiveStep(newActiveStep)
    handleCompletedRemove(activeStep)
  }

  const handleNext = () => {
    const newActiveStep = activeStep + 1
    setActiveStep(newActiveStep)
    handleCompletedAdd(newActiveStep)
  }

  const setDefaultStep = useCallback(() => {
    const newCompleted = new Set<number>()
    const calcSteps = Array.from({ length: activeStep + 1 }, (_, y) => y)
    calcSteps.forEach((stepItem) => {
      newCompleted.add(stepItem)
    })
    setCompleted(newCompleted)
  }, [activeStep])

  useEffect(() => {
    setDefaultStep()
  }, [setDefaultStep])

  function not(a: any[], b: any[]): any[] {
    return a.filter(
      (values) => b.findIndex((itemB) => itemB.id === values.id) === -1
    )
  }

  useQuery({
    queryKey: ['GetPortfolioTypesRelatedAssetTypes'],
    queryFn: async () =>
      axios({
        method: 'get',
        url: `${CONTROLPANEL_URL}/client.GetPortfolioTypesRelatedAssetTypes`,
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        const controlPanelPortfolioTypesData = mapPortfolioItems(result.data)
        const configuredPortfoliosTypes = controlPanelPortfolioTypesData.filter(
          (e) =>
            (inputDataForm.values.portfolioTypes || []).some(
              (item: any) => item.id === e.id
            )
        )
        const availablePortfoliosTypes = controlPanelPortfolioTypesData.filter(
          (e) => !configuredPortfoliosTypes.includes(e)
        )
        setPortfolioTypesAvailable(availablePortfoliosTypes)
        const selectedPortfolioTypes = portfolioTypesSelected
          .concat(configuredPortfoliosTypes)
          .sort((a, b) => a.name.localeCompare(b.name))
        setPortfolioTypesSelected(selectedPortfolioTypes)
        setActiveStep(1)
        const initialAssetTypes = selectedPortfolioTypes
          .reduce((acc, obj) => {
            obj.assetTypes.forEach((asset: any) => {
              if (!acc.some((item: any) => item.id === asset.id)) {
                acc.push(asset)
              }
            })
            return acc
          }, [])
          .sort((a: any, b: any) => a.name.localeCompare(b.name))

        const selectedAssetTypes = initialAssetTypes.filter((e: any) =>
          (inputDataForm.values.assetTypes || []).some(
            (item: any) => item.id === e.id
          )
        )
        const availableAssetTypes = initialAssetTypes.filter(
          (e: any) => !selectedAssetTypes.includes(e)
        )

        setAssetTypesAvailable(availableAssetTypes)
        setAssetTypesSelected(selectedAssetTypes)
        setActiveStep(0)
        firstUpdateAssetType.current = false
        firstUpdatePortfolioType.current = false
      }),
    cacheTime: 0,
  })

  const handleSendRightColumn = (data: any[]) => {
    const selectedPortfolioTypes = portfolioTypesSelected
      .concat(data)
      .sort((a, b) => a.name.localeCompare(b.name))
    setPortfolioTypesSelected(selectedPortfolioTypes)
    setPortfolioTypesAvailable(not(portfolioTypesAvailable, data))
    setAssetTypesAvailable(
      selectedPortfolioTypes
        .reduce((acc, obj) => {
          obj.assetTypes.forEach((asset: any) => {
            if (!acc.some((item: any) => item.id === asset.id)) {
              if (
                !assetTypesSelected.some((item: any) => item.id === asset.id)
              ) {
                acc.push(asset)
              }
            }
          })
          return acc
        }, [])
        .sort((a: any, b: any) => a.name.localeCompare(b.name))
    )
  }

  const handleSendLeftColumn = (data: any[]) => {
    setPortfolioTypesAvailable(
      portfolioTypesAvailable
        .concat(data)
        .sort((a, b) => a.name.localeCompare(b.name))
    )
    const selectedPortfolioTypes = not(portfolioTypesSelected, data)
    setPortfolioTypesSelected(selectedPortfolioTypes)
    setAssetTypesAvailable(
      selectedPortfolioTypes
        .reduce((acc, obj) => {
          obj.assetTypes.forEach((asset: any) => {
            if (!acc.some((item: any) => item.id === asset.id)) {
              if (
                !assetTypesSelected.some((item: any) => item.id === asset.id)
              ) {
                acc.push(asset)
              }
            }
          })
          return acc
        }, [])
        .sort((a: any, b: any) => a.name.localeCompare(b.name))
    )
    setAssetTypesSelected(
      selectedPortfolioTypes
        .reduce((acc, obj) => {
          obj.assetTypes.forEach((asset: any) => {
            if (!acc.some((item: any) => item.id === asset.id)) {
              if (
                assetTypesSelected.some((item: any) => item.id === asset.id)
              ) {
                acc.push(asset)
              }
            }
          })
          return acc
        }, [])
        .sort((a: any, b: any) => a.name.localeCompare(b.name))
    )
  }

  const handleAssetTypeSendLeftColumn = (data: any[]) => {
    setAssetTypesAvailable(
      assetTypesAvailable
        .concat(data)
        .sort((a, b) => a.name.localeCompare(b.name))
    )
    setAssetTypesSelected(not(assetTypesSelected, data))
  }

  const handleAssetTypeSendRightColumn = (data: any[]) => {
    setAssetTypesSelected(
      assetTypesSelected
        .concat(data)
        .sort((a, b) => a.name.localeCompare(b.name))
    )
    setAssetTypesAvailable(not(assetTypesAvailable, data))
  }

  const mapPortfolioItems = (array: any[]) =>
    array.map((e) => ({ id: e.id, name: e.typeName, assetTypes: e.assetTypes }))

  useEffect(() => {
    if (!firstUpdatePortfolioType.current) {
      handleChange(
        'portfolioTypes',
        portfolioTypesSelected.map((e) => ({ id: e.id, name: e.name })),
        true
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioTypesSelected])

  useEffect(() => {
    if (!firstUpdateAssetType.current) {
      handleChange(
        'assetTypes',
        assetTypesSelected.map((e) => ({ id: e.id, name: e.name })),
        true
      )
      handleFormFilled(assetTypesSelected.length > 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetTypesSelected])

  return (
    <>
      <Grid
        container
        direction="row"
        style={{ margin: '6px 6px 6px 6px', width: '860px' }}
        spacing={3}
      >
        <Box display="flex" p={4}>
          {steps.map((label, index) =>
            index === 0 || index > 0 ? (
              <StepperIndication
                key={label}
                label={label}
                active={completed.has(index)}
                stepNumber={index + 1}
                onChangeStep={() => {
                  setActiveStep(index)
                }}
              />
            ) : (
              <></>
            )
          )}
        </Box>
      </Grid>

      <Grid
        container
        direction="row"
        style={{ margin: '6px 6px 6px 6px', width: '860px' }}
        spacing={3}
      >
        <Box px={4}>
          {activeStep === 0 && (
            <>
              <TransferList
                leftData={portfolioTypesAvailable}
                rightData={portfolioTypesSelected}
                onSendLeftColumn={handleSendLeftColumn}
                onSendRightColumn={handleSendRightColumn}
                readonly={readOnly}
              />
            </>
          )}
        </Box>
        <Box px={4}>
          {activeStep === 1 && (
            <>
              <TransferList
                leftData={assetTypesAvailable}
                rightData={assetTypesSelected}
                onSendLeftColumn={handleAssetTypeSendLeftColumn}
                onSendRightColumn={handleAssetTypeSendRightColumn}
                readonly={readOnly}
              />
            </>
          )}
        </Box>
        <Grid
          container
          direction="row"
          style={{ margin: '6px 6px 6px 6px', width: '860px' }}
          spacing={3}
        >
          <Box p={4} display="flex" alignItems="center">
            <Box mr={4}>
              <Button
                type="button"
                variant="text"
                style={{ color: 'rgb(0, 46, 58)' }}
                onClick={handlePrevious}
                disabled={!activeStep}
              >
                Back
              </Button>
            </Box>
            {!isLastStep() ? (
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleNext}
              >
                Next
              </Button>
            ) : (
              <></>
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default PortfolioAssetTypes
