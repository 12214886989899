import React, { useState, useContext, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { AuthContext } from 'src/context/AuthenticationContext'
import { getPermissionsValues } from 'src/api/operations/get/permission'
import { CheckPermissionValues, Permissions } from 'src/api/models/permissions'
import { PermissionCodeAccess, PERMISSION_INFO_002 } from 'src/utils/constants'

import axios from 'axios'
import { ACCESSDENIED } from './routes'
import Main from './pages/Main'

import LoaderPage from './components/LoaderPage'

interface PermissionReduce {
  [field: string]: boolean
}

const permissionReduce = (
  permissionValues: Permissions[] = []
): PermissionReduce =>
  permissionValues.reduce(
    (acc: any, item: Permissions) => ({ ...acc, [item.code]: item.value }),
    {}
  )

const SwitchRouteProtect = () => {
  const [permissionCodes, setPermissionCodes] = useState<any[]>([])
  const [encodedPermissions, setEncodedPermissions] = useState('')
  const [loadingPermission, setLoadingPermission] = useState(true)
  const [loading, setLoading] = useState(false)
  const { user } = useContext<any>(AuthContext)

  const [managePermissionReduce, setManagePermissionReduce] =
    useState<PermissionReduce>()
  const [managePermission, setManagePermission] = useState<Permissions[]>([])
  const [permissionData, setPermissionData] = useState<CheckPermissionValues>()

  useEffect(() => {
    const { profile }: any = user || {}
    if (user && profile) {
      const permissionCode: any[] = Object.entries(PermissionCodeAccess).map(
        ([pName, pCode]) => pCode
      )
      setPermissionCodes(permissionCode)
      setEncodedPermissions(profile[PERMISSION_INFO_002])
    }
  }, [user])

  useEffect(() => {
    if (encodedPermissions !== '' && permissionCodes.length > 0) {
      setLoading(true)
      axios({
        url: process.env.REACT_APP_CONTROLPANEL,
        method: 'post',
        data: {
          query: `${getPermissionsValues(permissionCodes, encodedPermissions)}`,
        },
      }).then((result: any) => {
        setPermissionData(result.data)
        setLoading(false)
      })
    }
  }, [encodedPermissions, permissionCodes])

  useEffect(() => {
    if (permissionData) {
      setManagePermission(
        permissionData?.data.checkPermissionValues.permissions
      )
    }
  }, [permissionData])

  useEffect(() => {
    if (managePermission && managePermission.length > 0) {
      setManagePermissionReduce(permissionReduce(managePermission))
      setLoadingPermission(false)
    }
  }, [managePermission])

  if (
    (user && user.isAuthenticated && !managePermission && loadingPermission) ||
    loading
  ) {
    return <LoaderPage />
  }

  if (
    user &&
    user.isAuthenticated &&
    managePermissionReduce &&
    !managePermissionReduce[PermissionCodeAccess.ComplianceManagementSystem]
  ) {
    return <Redirect to={ACCESSDENIED} />
  }

  return (
    <Switch>
      <Route>
        <Main managePermission={managePermission || []} />
      </Route>
    </Switch>
  )
}

export default SwitchRouteProtect
