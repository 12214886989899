import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import axios from 'axios'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useLocation, useParams } from 'react-router-dom'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import Loader from 'src/components/Loader'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { getLocaleDateString } from 'src/utils/date'
import SOPDraft from 'src/components/SOPDraft'
import { getInternalUsers } from 'src/api/operations/get/business'
import hardcodeData from 'src/utils/hardcodeData'
import SOPVersioning from 'src/components/SOPVersioning'

interface ICorporateSOP {
  name: string
  status: string
  department: string
  owner: string
  nextAuditDueDateUtc?: Date | null
  createDateUtc?: Date | null
}

const CorporateSOPDetail: React.FC = () => {
  const { id } = useParams<any>()

  const path = window.location.pathname.split('/')
  const cardType = path[path.length - 1]

  const [sopToDownload, setSopToDownload] = useState<any | null>(null)
  const userToken = GetAccessToken()
  const [saving, setSaving] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const notistackSucces = notistackOptions('success')
  const notistackError = notistackOptions('error')
  const [internalUsers, setInternalUsers] = useState<any[]>([])
  const [lastSOPUpdateData, setLastSOPUpdateData] = useState(null)

  const currentDate = new Date()
  const nextDay = new Date(currentDate)
  nextDay.setDate(currentDate.getDate() + 1)
  const queryClient = useQueryClient()

  const [initialValues, setInitialValues] = useState<ICorporateSOP>({
    name: '',
    status: '',
    department: '',
    owner: '',
    createDateUtc: null,
    nextAuditDueDateUtc: null,
  })

  useQuery({
    queryKey: ['getInternalUsers'],
    queryFn: async () => {
      const result = await getInternalUsers()

      setInternalUsers(result)
    },
    cacheTime: 0,
  })

  const inputDataFormSchema = Yup.object().shape({
    owner: Yup.string().trim().required('Required'),
    department: Yup.string().trim().required('Required'),
  })

  const getCorporateSOP = useQuery({
    queryKey: ['getCorporateSOP', id],
    cacheTime: 0,
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/corporateSOP/getCorporateSOP`,
        params: {
          id,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setInitialValues(result.data)
        setLastSOPUpdateData(result.data)
      })
    },
  })

  const downloadLastSOPVersion = useQuery({
    queryKey: ['downloadLastSOPVersion', sopToDownload],
    cacheTime: 0,
    queryFn: async () => {
      return axios({
        method: 'GET',
        url: `${BACKEND_URL}/corporateSOP/downloadLastSOPVersion`,
        params: {
          id,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setSopToDownload(null)
        window.location.href = result.data || ''
      })
    },
    enabled: sopToDownload !== null,
  })
  const handleDownloadFile = () => {
    setSopToDownload(id)
  }

  const inputDataForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: inputDataFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      const requestValues = {
        id,
        name: values.name || null,
        status: values.status || null,
        department: values.department || null,
        owner: values.owner,
        ownerId:
          internalUsers.find((f: any) => f.DisplayName === values.owner)?.Id ||
          '',
        nextAuditDueDateUtc: values.nextAuditDueDateUtc || null,
        createDateUtc: values.createDateUtc,
      }

      setSaving(true)
      axios({
        method: 'post',
        url: `${BACKEND_URL}/corporatesop`,
        data: requestValues,
        headers: {
          Authorization: userToken,
        },
      })
        .then(() => {
          enqueueSnackbar('SOP updated.', notistackSucces)
          queryClient.refetchQueries({
            queryKey: 'getCorporateSOPGrid',
          })
          setSaving(false)
        })
        .catch((error) => {
          enqueueSnackbar('Error on update SOP.', notistackError)
          setSaving(false)
        })

      setSubmitting(false)
    },
  })

  const availableOwners: any[] =
    (inputDataForm.values.department &&
      internalUsers &&
      internalUsers.filter(
        (i: any) =>
          i.Department !== null &&
          i.Department?.toLowerCase() ===
            inputDataForm.values.department?.toLowerCase()
      )) ||
    []

  const loading = getCorporateSOP.isLoading
  return (
    <>
      <Box padding={4} mt={4}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle1">Corporate SOP Detail</Typography>
          </Grid>
        </Grid>
      </Box>

      <form onSubmit={inputDataForm.handleSubmit}>
        <Grid container md={12} sm={12}>
          <Grid item xs={12}>
            <Paper style={{ margin: 10, padding: 5 }}>
              <Box m={5}>
                {loading && <Loader />}

                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      data-cy="dialog-input-field-name"
                      label="Name"
                      name="name"
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={inputDataForm.handleChange}
                      value={inputDataForm.values.name}
                      error={!!inputDataForm.errors.name}
                      helperText={inputDataForm.errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      data-cy="dialog-input-field-status"
                      label="Status"
                      name="status"
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={inputDataForm.handleChange}
                      value={inputDataForm.values.status}
                      error={!!inputDataForm.errors.status}
                      helperText={inputDataForm.errors.status}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <KeyboardDatePicker
                      style={{ width: '100%' }}
                      format={getLocaleDateString()}
                      id="createDateUtc"
                      disabled={true}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Create Date"
                      name="createDateUtc"
                      onChange={(date: any) => {
                        inputDataForm.setFieldValue('createDateUtc', date, true)
                      }}
                      value={inputDataForm.values.createDateUtc}
                      error={!!inputDataForm.errors.createDateUtc}
                      helperText={<>{inputDataForm.errors.createDateUtc}</>}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      disabled={saving}
                      fullWidth
                      label="Department"
                      name="department"
                      select
                      onChange={inputDataForm.handleChange}
                      error={!!inputDataForm.errors.department}
                      value={inputDataForm.values.department}
                      helperText={<>{inputDataForm.errors.department}</>}
                    >
                      {hardcodeData.getDepartments().map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      disabled={saving}
                      fullWidth
                      label="Owner"
                      name="owner"
                      select
                      onChange={inputDataForm.handleChange}
                      error={!!inputDataForm.errors.owner}
                      value={inputDataForm.values.owner}
                      helperText={<>{inputDataForm.errors.owner}</>}
                    >
                      {availableOwners?.map((option) => (
                        <MenuItem key={option.Id} value={option.DisplayName}>
                          {option.DisplayName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <KeyboardDatePicker
                      style={{ width: '100%' }}
                      format={getLocaleDateString()}
                      id="nextAuditDueDateUtc"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label="Next Audit Due Date"
                      name="nextAuditDueDateUtc"
                      onChange={(date: any) => {
                        inputDataForm.setFieldValue(
                          'nextAuditDueDateUtc',
                          date,
                          true
                        )
                      }}
                      minDate={nextDay}
                      value={inputDataForm.values.nextAuditDueDateUtc || null}
                      error={!!inputDataForm.errors.nextAuditDueDateUtc}
                      helperText={
                        <>{inputDataForm.errors.nextAuditDueDateUtc}</>
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box
                mt={5}
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
              >
                {cardType === 'master' && (
                  <Button
                    style={{ marginRight: 5 }}
                    data-cy="download-last-button"
                    variant="contained"
                    color="primary"
                    type="button"
                    disabled={downloadLastSOPVersion.isLoading}
                    onClick={() => handleDownloadFile()}
                  >
                    Download SOP
                  </Button>
                )}
                <Button
                  style={{ marginRight: 5 }}
                  data-cy="submit-button"
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Submit
                </Button>
              </Box>
            </Paper>
          </Grid>
          {cardType !== 'master' && lastSOPUpdateData !== null && (
            <Grid item xs={12} md={6} lg={6}>
              <SOPVersioning sop={lastSOPUpdateData} />
            </Grid>
          )}
          {cardType !== 'master' && (
            <Grid item xs={12} md={6} lg={6}>
              <SOPDraft id={id} />
            </Grid>
          )}
        </Grid>
      </form>
    </>
  )
}

export default CorporateSOPDetail
