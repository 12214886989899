import React, { useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@material-ui/core'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useFormik } from 'formik'
import axios from 'axios'
import { MaxFileSize } from 'src/utils/constants'
import { Delete, Description } from '@material-ui/icons'
import { UploadItem } from 'src/components/UploadDragDrop/styles'
import UploadDragDrop from 'src/components/UploadDragDrop'
import { DialogTitleStyled } from 'src/layouts/Main/styles'
import CloseIcon from '@material-ui/icons/Close'

interface IUploadTemplateInfo {
  businessUploadRequest: number
}

interface IUploadTemplate {
  businessUploadRequest: number
  openDialog: boolean
  close: () => void
}

const UploadTemplate: React.FC<IUploadTemplate> = ({
  close,
  businessUploadRequest,
  openDialog,
}: IUploadTemplate) => {
  const MAX_SIZE_FILE = MaxFileSize

  const userToken = GetAccessToken()
  const { enqueueSnackbar } = useSnackbar()

  const notistackSucces = notistackOptions('success')

  const [saving, setSaving] = useState(false)

  const [files, setFiles] = useState<File[] | null>(null)

  const [initialValues, setInitialValues] = useState<IUploadTemplateInfo>({
    businessUploadRequest,
  })

  const inputDataForm = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      const formData = new FormData()
      setSaving(true)
      files?.forEach((file: File) => {
        formData.append('files', file, file.name)
      })

      axios({
        method: 'post',
        url: `${BACKEND_URL}/businessUpload/ApproveFile`,
        data: formData,
        headers: {
          Authorization: userToken,
        },
        params: {
          businessUploadRequest,
        },
      })
        .then(() => {
          enqueueSnackbar('Template uploaded.', notistackSucces)
          setFiles(null)
          inputDataForm.resetForm()
          setSaving(false)
          close()
        })
        .catch((error) => {
          enqueueSnackbar(
            'Error on uploading template.',
            notistackOptions('error')
          )
          setSaving(false)
        })

      setSubmitting(false)
    },
  })

  const handleFileRejected = () => {
    enqueueSnackbar('The file has been rejected.', notistackOptions('warning'))
  }

  const handleDroppedFiles = (acceptedFiles: File[]) => {
    setFiles(acceptedFiles)
  }

  const handleUploadFileRemove = (index: number) => {
    if (files) {
      setFiles(files.filter((f) => f.name !== files[index].name))
    }
  }

  return (
    <Dialog open={openDialog} onClose={() => close()} maxWidth="md">
      <DialogTitleStyled id="dialog-title">
        <Typography
          variant="body1"
          style={{
            color: 'white',
            fontSize: '1.5rem',
            fontWeight: 400,
            lineHeight: 1.2,
            textAlign: 'center',
          }}
        >
          Upload Template
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => close()}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitleStyled>
      <DialogContent>
        <form onSubmit={inputDataForm.handleSubmit}>
          <Box width="100%" minWidth="480px">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                {files && files.length > 0 && (
                  <UploadItem>
                    <Grid container>
                      <Grid item xs={12}>
                        <List
                          style={{
                            marginBottom: 2,
                            width: '100%',
                          }}
                        >
                          {files.map((file: any, idx: number) => {
                            return (
                              <ListItem
                                alignItems="flex-start"
                                key={`item_${idx}`}
                              >
                                <div
                                  className="upload-item-info"
                                  key={`div_${idx}`}
                                >
                                  <Description
                                    key={`DescriptionIcon_${idx}`}
                                    fontSize="small"
                                    color="primary"
                                    className="upload-item-icon"
                                  />
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="span"
                                    key={`Typography_${file.name}`}
                                  >
                                    {file.name.length > 200
                                      ? `${file.name.substring(0, 15)}...`
                                      : file.name}
                                  </Typography>
                                  <IconButton
                                    size="small"
                                    key={`Button_${idx}`}
                                    onClick={() => {
                                      handleUploadFileRemove(idx)
                                    }}
                                  >
                                    <Delete
                                      key={`DeleteButton_${idx}`}
                                      className="upload-item-icon"
                                      fontSize="small"
                                    />
                                  </IconButton>
                                </div>
                              </ListItem>
                            )
                          })}
                        </List>
                      </Grid>
                    </Grid>
                  </UploadItem>
                )}
                {(files === null || files.length === 0) && (
                  <UploadDragDrop
                    linkText="Upload File"
                    text=" Excel files only (max. 200mb)"
                    size="small"
                    multiple={false}
                    onDrop={(acceptedFiles: any) => {
                      handleDroppedFiles(acceptedFiles)
                    }}
                    maxSize={MAX_SIZE_FILE}
                    onDropRejected={handleFileRejected}
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                  />
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                >
                  <Button
                    disabled={files === null || files.length === 0 || saving}
                    data-cy="load-sop-button"
                    variant="contained"
                    color="primary"
                    title="Upload"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default UploadTemplate
