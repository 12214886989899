import React from 'react'

interface LoaderProps {
  // eslint-disable-next-line react/require-default-props
  specificHeight?: number | string | undefined
}
const Loader: React.FC<LoaderProps> = ({ specificHeight }) => {
  const loadingPanel = (
    <div
      className="k-loading-mask"
      style={{
        height: specificHeight || 200,
        position: 'absolute',
        top: '30%',
      }}
    >
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  return loadingPanel
}

export default Loader
